import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

const Pagination = ({ currentPage, numPages, path }) => {
  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;

  const prevPage = currentPage - 1 === 1 ? '/' : (currentPage - 1).toString();
  const nextPage = (currentPage + 1).toString();

  return (
    <div className="pagination">
      <ul className="pagination__list">
        {!isFirst && (
          <Link to={`${path}/${prevPage}`} rel="prev">
            ← Previous Page
          </Link>
        )}

        {Array.from({ length: numPages }, (_, i) => (
          <li className="pagination__item" key={`pagination-number${i + 1}`}>
            <Link
              className={i + 1 === currentPage ? 'pagination__link_active' : ''}
              to={`${path}/${i === 0 ? '' : i + 1}`}
            >
              {i + 1}
            </Link>
          </li>
        ))}

        {!isLast && (
          <Link to={`${path}/${nextPage}`} rel="next">
            Next Page →
          </Link>
        )}
      </ul>
    </div>
  );
};

Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  numPages: PropTypes.number.isRequired,
  path: PropTypes.string.isRequired,
};

export default Pagination;
