import React, { Component } from 'react';
import { Link, withTranslation } from 'gatsby-plugin-react-i18next';

import LanguageSwitcher from './LanguageSwitcher';
import Logo from '../../assets/images/logo.svg';
import HamburgerMenu from './HamburgerMenu';
import MobileNav from './MobileNav';
import Button from '../Button';

const classesAtTopDefault = 'fullNav main__inner';
const classesScrolledDefault = 'fullNav-scroll fullNav main__inner';
const classesAtTopMobileDefault = 'mobileNav';
const classesScrolledMobile = 'mobileNav mobileNav-scroll';

class Navigation extends Component {
  constructor(props) {
    super(props);
    const url = typeof window !== 'undefined' ? window.location.href : '';
    const isWebinar = url.includes('webinar');

    const classesAtTop = isWebinar
      ? `${classesAtTopDefault} fullNav-center fullNav-main-background`
      : classesAtTopDefault;
    const classesScrolled = isWebinar ? `${classesScrolledDefault} fullNav-center` : classesScrolledDefault;

    let defaultIsWhite = false;
    let startClassesFullNav = classesAtTop;
    let startClassesFullMobile = isWebinar
      ? `${classesAtTopMobileDefault} fullNav-main-background`
      : classesAtTopMobileDefault;

    if (typeof window !== 'undefined') {
      if (window.scrollY > 0) {
        startClassesFullNav = classesScrolled;
        startClassesFullMobile = classesScrolledMobile;
        defaultIsWhite = true;
      }
    }

    this.state = {
      isWhite: defaultIsWhite,
      open: false,
      classesFullNav: startClassesFullNav,
      classesMobileNav: startClassesFullMobile,
      classesAtTop: classesAtTop,
      isWebinar: isWebinar,
      classesScrolled: classesScrolled,
      classesAtTopMobile: startClassesFullMobile,
    };
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', this.handleScroll);
    }
  }

  componentWillUnmount() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('scroll', this.handleScroll);
    }
  }

  handleScroll = () => {
    const { isWhite } = this.state;
    if (typeof window !== 'undefined') {
      if (isWhite === false && window.scrollY > 0) {
        // was transparent
        this.setState({
          isWhite: true,
          classesFullNav: this.state.classesScrolled,
          classesMobileNav: classesScrolledMobile,
        });
      } else if (isWhite === true && window.scrollY === 0) {
        // was white
        this.setState({
          isWhite: false,
          classesFullNav: this.state.classesAtTop,
          classesMobileNav: this.state.classesAtTopMobile,
        });
      }
    }
  };

  closeNav = () => {
    this.setState({
      open: false,
    });
  };

  toggleNavigation = () => {
    const { open } = this.state;

    this.setState({
      open: !open,
    });
  };

  render() {
    const { t } = this.props;
    const { open, classesFullNav, classesMobileNav } = this.state;

    if (this.state.isWebinar) {
      return (
        <nav>
          <div className={classesFullNav}>
            <div className="oneSide">
              <div className="logo">
                <Link to="/">
                  <img src={Logo} alt="DOCVISER" title="DOCVISER" height="28" />
                </Link>
              </div>
            </div>
          </div>
          <div className={classesMobileNav} px={[2, 3, 4]}>
            <Link to="/" className="logo">
              <img src={Logo} alt="DOCVISER" title="DOCVISER" height="25" />
            </Link>
          </div>
        </nav>
      );
    }

    return (
      <nav>
        <div className={classesFullNav}>
          <div className="logo">
            <Link to="/">
              <img src={Logo} alt="DOCVISER" title="DOCVISER" height="28" />
            </Link>
          </div>
          <div className="oneSide">
            <ul>
              <li>
                <Link activeClassName="selected" to="/">
                  {t('navigation.home')}
                </Link>
              </li>
              <li>
                <Link activeClassName="selected" to="/product/">
                  {t('navigation.product')}
                </Link>
              </li>
              <li>
                <Link activeClassName="selected" to="/about-us/">
                  {t('navigation.about')}
                </Link>
              </li>
              <li>
                <Link activeClassName="selected" to="/faq/">
                  {t('navigation.faq')}
                </Link>
              </li>
              <li>
                <Link activeClassName="selected" to="/blog/">
                  {t('navigation.blog')}
                </Link>
              </li>
              <li>
                <Link activeClassName="selected" to="/contact/">
                  {t('navigation.contact')}
                </Link>
              </li>
            </ul>
          </div>
          <div className="oneSide">
            <Button goTo="/contact/" title={t('navigation.getStarted')} type="primary-small-dark" />
            <LanguageSwitcher />
          </div>
        </div>
        <div className={classesMobileNav} px={[2, 3, 4]}>
          <ul>
            <li>
              <Link to="/" className="logo">
                <img src={Logo} alt="DOCVISER" title="DOCVISER" height="25" />
              </Link>
            </li>
            <li>
              <div
                className="hamburger-menu"
                aria-label="hamburger-menu"
                onClick={this.toggleNavigation}
                role="button"
                tabIndex="0"
                onKeyPress={this.toggleNavigation}
              >
                <HamburgerMenu
                  isOpen={open}
                  width={28}
                  height={15}
                  strokeWidth={1}
                  rotate={0}
                  color="black"
                  borderRadius={0}
                  animationDuration={0.5}
                />
              </div>
            </li>
          </ul>
        </div>
        <MobileNav open={open} closeNav={this.closeNav}>
          <ul>
            <li>
              <div onClick={this.closeNav} onKeyPress={this.closeNav}>
                <Link to="/">{t('navigation.home')}</Link>
              </div>
            </li>
            <li>
              <div onClick={this.closeNav} onKeyPress={this.closeNav}>
                <Link to="/product/">{t('navigation.product')}</Link>
              </div>
            </li>
            <li>
              <div onClick={this.closeNav} onKeyPress={this.closeNav}>
                <Link to="/about-us/">{t('navigation.about')}</Link>
              </div>
            </li>
            <li>
              <div onClick={this.closeNav} onKeyPress={this.closeNav}>
                <Link to="/faq/">{t('navigation.faq')}</Link>
              </div>
            </li>
            <li>
              <div onClick={this.closeNav} onKeyPress={this.closeNav}>
                <Link to="/blog/">{t('navigation.blog')}</Link>
              </div>
            </li>
            <li>
              <div onClick={this.closeNav} onKeyPress={this.closeNav}>
                <Link to="/contact/">{t('navigation.contact')}</Link>
              </div>
            </li>
            <LanguageSwitcher isMobile />
          </ul>
        </MobileNav>
      </nav>
    );
  }
}

export default withTranslation()(Navigation);
