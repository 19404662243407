import React from 'react';
import { graphql } from 'gatsby';

import Logo from '../assets/images/logo.svg';
import Twitter from '../assets/images/Twitter.svg';
import Facebook from '../assets/images/Facebook.svg';
import Linkedin from '../assets/images/Linkedin.svg';
import { Link, withTranslation } from 'gatsby-plugin-react-i18next';

const Footer = ({ t }) => {
  return (
    <footer id="footer" className="main__inner">
      <div className="footer">
        <div className="footer__item footer__item-logo">
          <Link to="/" target="_self">
            <img src={Logo} alt="DOCVISER" title="DOCVISER" width="110" height="29" />
          </Link>
          <p>
            {t('footer.alreadyHaveAccount')}{' '}
            <a href="https://oncology.docviser.io" rel="noopener noreferrer">
              {t('footer.logIn')}
            </a>
          </p>
        </div>
        <div className="footer__main-section">
          <div className="footer__item">
            <Link to="/">{t('navigation.home')}</Link>
            <Link to="/product/">{t('navigation.product')}</Link>
            <Link to="/about-us/">{t('navigation.about')}</Link>
            <Link to="/faq/">{t('navigation.faq')}</Link>
            <Link to="/blog/">{t('navigation.blog')}</Link>
            <Link to="/contact/">{t('navigation.contact')}</Link>
          </div>

          <div className="footer__main-section footer__main-section-item">
            <div className="footer__item">
              <Link to="/privacy-policy/" className="footer__item-link">
                {t('navigation.privacy')}
              </Link>
              <Link to="/terms-and-conditions/" className="footer__item-link">
                {t('navigation.terms')}
              </Link>
            </div>
            <div className="footer__item">
              <div className="footer__item  footer__item-columns">
                <p className="font-weight-light-bold">{t('navigation.office')}</p>
                <p>Marin Preda 10-12, Cluj-Napoca, Romania, 400502</p>
              </div>
            </div>

            <div className="footer__main-section footer__main-section-item">
              <div className="footer__item">
                <div className="footer__item  footer__item-columns">
                  <p className="font-weight-light-bold">Contact</p>
                  <p>
                    <a href="tel:+40747215726" rel="noopener noreferrer" className="footer__item-link">
                      +4 074 721 5726
                    </a>
                  </p>
                  <p>
                    <a href="mailto:contact@docviser.io" rel="noopener noreferrer" className="footer__item-link">
                      contact@docviser.io
                    </a>
                  </p>
                </div>
              </div>
              <div className="footer__item">
                <p>{t('footer.followUs')}</p>
                <div className="footer__item footer__item-row">
                  <a href="https://twitter.com/DocviserIO" rel="noopener noreferrer" className="footer__item-link">
                    <img src={Twitter} alt="Twitter" title="Twitter" width="24" height="24" />
                  </a>
                  <a href="https://www.facebook.com/docviser" rel="noopener noreferrer" className="footer__item-link">
                    <img src={Facebook} alt="Facebook" title="Facebook" width="24" height="24" />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/docviser/"
                    rel="noopener noreferrer"
                    className="footer__item-link"
                  >
                    <img src={Linkedin} alt="Linkedin" title="Linkedin" width="24" height="24" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { ns: { in: ["index"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default withTranslation()(Footer);
