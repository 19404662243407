import React, {useState, useEffect} from "react";

const Timer = () => {
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = React.useState(0);
  
  const deadline = "2022-11-17T15:00:00Z";
  
  const getTime = () => {
    const time = Date.parse(deadline) - Date.now();
    
    setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
    setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
    setMinutes(Math.floor((time / 1000 / 60) % 60));
    setSeconds(Math.floor((time / 1000) % 60));
  };
  
  useEffect(() => {
    const interval = setInterval(() => getTime(deadline), 1000);
    
    return () => clearInterval(interval);
  }, []);
  
  return (
    <div className="timer-container">
      <div className={'main content'}>
        <div className="timer" role="timer">
          <div className="box">
            <div className={'time'} id="day">{days < 10 ? "0" + days : days}</div>
            <div className="text">Zile</div>
          </div>
          <div className={'divider'}/>
          <div className="box">
            <div className={'time'} id="hour">{hours < 10 ? "0" + hours : hours}</div>
            <div className="text">Ore</div>
          </div>
          <div className={'divider'}/>
          <div className="box">
            <div className={'time'} id="minute">{minutes < 10 ? "0" + minutes : minutes}</div>
            <div className="text">Minute</div>
          </div>
          <div className={'divider'}/>
          <div className="box">
            <div className={'time'} id="seconds">{seconds < 10 ? "0" + seconds : seconds}</div>
            <div className="text">Secunde</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Timer;
