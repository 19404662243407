import React, { Component } from 'react';

import NewQuoteForm from './NewQuoteForm';
import MailIcon from '../assets/images/mail-icon.svg';
import PhoneIcon from '../assets/images/phone-icon.svg';
import LocationIcon from '../assets/images/location-icon.svg';

export default class ContactForm extends Component {
  render() {
    const { t } = this.props;

    return (
      <div className="contact-card">
        <div className="contact-card-info">
          <div className="title-container title-container-left">
            <h2 className="home-title">
              {t('contact.title')}
              <span className={'home-title text-gradient'}>{t('contact.title2')}.</span>
            </h2>
            <div className="description">{t('contact.description')}</div>
          </div>
          <div className="horizontal-line" />

          <div className="text-container">
            <div className={'testimonials-text weight600'}>Cluj-Napoca, Romania</div>
            <div className="row">
              <div className="contact-card-info-email" onClick={this.openEmailClient}>
                <img src={LocationIcon} alt="Location" title="Location" width="32" height="32" />
                <div className={'home-subtitle'}>Marin Preda 10-12, 400502</div>
              </div>
              <div className="contact-card-info-email" onClick={this.openEmailClient}>
                <img src={MailIcon} alt="Mail" title="Mail" width="32" height="32" />
                <a className="home-subtitle" tabIndex="0" href="mailto:contact@docviser.io" target="_blank">
                  contact@docviser.io
                </a>
              </div>
              <div className="contact-card-info-phone">
                <img src={PhoneIcon} alt="Phone" title="Phone" width="32" height="32" />
                <a className="home-subtitle" href="tel:+40 747 215 726">
                  +40 747 215 726
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="contact-card-form">
          <div className="contact-form">
            <NewQuoteForm />
          </div>
        </div>
      </div>
    );
  }
}
