// When building, Gatsby renders components on the server, where window isn't defined
export const getWindowHref = () => {
  const windowGlobal = typeof window !== 'undefined' && window;
  return windowGlobal ? windowGlobal.location.href : '';
};

export const getPathSegmentAfterCertainOne = (previousSegment, url) => {
  const urlToUse = url || getWindowHref();

  return decodeURIComponent(urlToUse.split(`${previousSegment}/`, -1)[1] || '');
};
