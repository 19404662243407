import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import Layout from '../components/GatsbyLayout';
import SignUp from '../components/Webinar/SignUp';
import Testimonials from '../components/Webinar/Testimonials';
import Subjects from '../components/Webinar/Subjects';
import Speakers from '../components/Webinar/Speakers';
import Timer from '../components/Webinar/Timer';
import TitleLine from '../components/Subsection/TitleLine';
import Button from '../components/Button';
import ExitIcon from '../assets/images/webinar/exit.svg';
import DevicesIcon from '../assets/images/webinar/devices.svg';
import Helmet from '../components/Helmet';
import Devices from '../assets/images/webinar/Devices.png';

const Webinar = ({ location }) => {
  const ref = useRef(null);

  const handleClick = () => {
    if (typeof window !== 'undefined') {
      window.open('https://meet.google.com/yfn-hkpp-ghi', '_blank', 'noopener,noreferrer');
    }
  };

  const renderHelmet = () => {
    const siteTitle = 'Docviser webinar | Înscrie-te Gratuit Acum | Inovatie pentru Centrele Oncologice';
    const siteDescription =
      'Docviser revolutioneaza tratarea pacientilor din Centrele Oncologice din Romania prin solutii software inovatoare. Afla ce beneficii considerabile poate aduce clinicii tale.';
    const siteKeywords = `Spital, inscriere webinar, institut oncologic, consultatie, oncologie, oncology, medicină, medic oncolog, clinica medicala, webinar, webinar docviser, webinar oncologie, cancer, protocoale tratament, pacient oncologie, solutii software`;

    return (
      <Helmet title={siteTitle} description={siteDescription} keywords={siteKeywords} canonical thumbnail={Devices} />
    );
  };

  return (
    <Layout location={location}>
      {renderHelmet()}
      <h1 className="show-for-seo">Docviser webinar | Înscrie-te Gratuit Acum | Inovatie pentru Centrele Oncologice</h1>
      <div className={'main-section'}>
        <div className="home-main__text-content">
          <div className={'title'}>
            <TitleLine title="Docviser Webinar: " classNames={'title-docviser'} />
            <TitleLine title="Soluții Software Inovatoare" classNames={'normal-title'} />
          </div>
          <TitleLine title="Pentru Centrele Oncologice" classNames="main-title normal-title" />
          <div className={'main-body-text margin-top-16 description'}>
            Vrei să afli cum revoluționează Docviser tratarea pacienților din Centrele Oncologice din România?
            Inscrie-te la webinar!
          </div>
          <div className="home-main__buttons-wrapper buttons-wrapper">
            <Button onClick={handleClick} type="primary-dark-button" title="Alătură-te Webinarului" />
            <Button
              goTo="/"
              title="Vezi Website-ul"
              type="secondary-large-icon-external"
              icon={ExitIcon}
              classNames={'home-main__buttons-wrapper-margin-left website-button'}
            />
          </div>
          <img src={DevicesIcon} alt="devices" width="858px" height="335px" className="image" />
        </div>
      </div>

      <Timer />
      <Speakers />
      <Subjects />
      <Testimonials />

      <div ref={ref}>
        <SignUp />
      </div>
    </Layout>
  );
};

Webinar.propTypes = {
  location: PropTypes.shape({}).isRequired,
};

export default Webinar;
