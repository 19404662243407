import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql, navigate } from 'gatsby';
import Loadable from 'react-loadable';

import Helmet from '../components/Helmet';
import Layout from '../components/GatsbyLayout';
import Subtitle from '../components/Subsection/Subtitle';
import Logo from '../assets/images/docviser-team-avatar.svg';

import Article from '../components/Article';
import { withTranslation } from 'gatsby-plugin-react-i18next';
import { resetLanguage } from '../util/lang';

const Loading = () => <h3>Loading...</h3>;

const SocialShareComponent = Loadable({
  loader: () => import('../components/SocialShare'),
  loading: Loading,
});

class BlogPostTemplate extends Component {
  state = {
    isMobile: false,
  };

  componentDidMount() {
    const { location } = this.props;

    this.updateIsMobile();
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', this.updateIsMobile);
    }

    if ((location.state || {}).prevPath === '/blog/' || (location.state || {}).prevPath === '/ro/blog/') {
      localStorage.setItem('blog', 'true');
    }
    resetLanguage();
  }

  componentWillUnmount() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.updateIsMobile);
    }
  }

  renderSocialShare(title) {
    const {
      location: { href },
    } = this.props;

    return (
      <div id="article-social-share" className="post__share">
        <span className="post__share-title">Share</span>

        <SocialShareComponent socialType="Facebook" quote={title} url={href} />

        <SocialShareComponent socialType="Twitter" quote={title} url={href} />

        <SocialShareComponent socialType="Linkedin" quote={title} url={href} />
      </div>
    );
  }

  renderHelmet(seo, author, mainImage, path) {
    const { title, description, keywords } = seo;

    return (
      <Helmet
        title={`${title} | DOCVISER`}
        description={`${description}`}
        keywords={`${keywords}`}
        author={author && author.id}
        thumbnail={mainImage.childImageSharp.fluid.src}
        canonical
        canonicalPage={path.slice(1).slice(0, path.length - 2)}
      />
    );
  }

  renderLatestArticles(latestPosts, t) {
    return (
      <div id="article-latest" className="post__latest-section">
        <Subtitle title={t('blogList.moreOnBlog')} withoutLine titleType="h2" />
        <div className="main__section__content post__latest-items">
          <div className="articles">{latestPosts}</div>
        </div>
      </div>
    );
  }

  updateIsMobile = () => {
    if (typeof window !== 'undefined') {
      this.setState({ isMobile: window.innerWidth < 1100 });
    }
  };

  render() {
    const {
      data: { allMarkdownRemark: allPosts },
      location,
      t,
      path,
    } = this.props;
    const post = (
      (Array.isArray(allPosts.edges) &&
        allPosts.edges.find(({ node }) => {
          return node.frontmatter.path === path.replace(`/${t('lang')}`, '');
        })) ||
      {}
    ).node;
    if (!post) return;
    const {
      timeToRead,
      frontmatter: { title, description, keywords, author, date, lang, category, idd, mainImage, banner },
    } = post;
    const { isMobile } = this.state;

    if (t('lang') && t('lang') !== lang) {
      const foundPost = (
        (Array.isArray(allPosts.edges) &&
          allPosts.edges.find(({ node }) => {
            return node.frontmatter.lang === t('lang') && node.frontmatter.idd === idd;
          })) ||
        {}
      ).node;
      if (foundPost) {
        setTimeout(() => {
          const navigateToPath = `${t('lang') !== 'en' ? `/${t('lang')}` : ''}${foundPost.frontmatter.path}`;
          navigate(navigateToPath);
        }, 0);
      }
    }

    const latestPosts = allPosts.edges
      .filter(
        ({ node }) =>
          !!node.frontmatter.date &&
          post &&
          node.frontmatter.idd !== post.frontmatter.idd &&
          node.frontmatter.lang === post.frontmatter.lang
      )
      .slice(0, 3)
      .map(({ node }) => (
        <Article
          key={node.id}
          title={node.frontmatter.title}
          category={node.frontmatter.category}
          readingTime={node.timeToRead}
          date={node.frontmatter.date}
          path={node.frontmatter.path}
          imageFluid={node.frontmatter.mainImage.childImageSharp.fluid}
        />
      ));
    console.log('latestPosts ------------------->> ', latestPosts);

    return (
      <Layout location={location}>
        {this.renderHelmet({ title, description, keywords }, author, mainImage, path)}

        <div className="main post">
          <div className="main__inner">
            <div className="main__section post__section">
              <div id="article-post-content" className="post__content">
                <div className="tags__item tags__active post__category">
                  <span className="tags__name">{category}</span>
                </div>
                <Subtitle
                  title={title}
                  classNames="text-uppercase"
                  withoutLine
                  titleType="h1"
                  blogTitle
                  titleClassNames="post__title"
                />
                <div className="post-link__content_share">
                  <div className="post-link__info-text font-title2">
                    <div className="blog-subtitle__font" tabIndex="0">
                      <i className="fa fa-calendar" />
                      {`${date}`}
                    </div>
                    <div className="blog-subtitle__font" tabIndex="0">
                      <i className="fa fa-clock-o" />
                      {`${timeToRead} min Read`}
                    </div>
                  </div>
                  {!isMobile && this.renderSocialShare(title)}
                </div>

                <img
                  className="main__section__image"
                  src={banner.childImageSharp.fluid.src}
                  alt={title}
                  title={title}
                />

                <div className="main__section__content" tabIndex="0" dangerouslySetInnerHTML={{ __html: post.html }} />

                <div className="main__section__content post__author-container">
                  <img src={Logo} alt={'Author'} className="post__author-avatar" />

                  <div className="post__author">
                    <p className="post__author-name" tabIndex="0">
                      Docviser Team
                    </p>
                  </div>
                </div>
                <div className="post__social-media">{isMobile && this.renderSocialShare(title)}</div>
              </div>
            </div>
          </div>

          {latestPosts.length ? this.renderLatestArticles(latestPosts, t) : null}
        </div>
      </Layout>
    );
  }
}

BlogPostTemplate.propTypes = {
  data: PropTypes.shape({}),
  location: PropTypes.shape({}).isRequired,
  t: PropTypes.shape(() => {}),
};
BlogPostTemplate.defaultProps = {
  data: null,
};

export default withTranslation()(BlogPostTemplate);

export const pageQuery = graphql`
  query BlogPostByPath($language: String!) {
    locales: allLocale(filter: { ns: { in: ["index"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { frontmatter: { date: DESC } }) {
      edges {
        node {
          id
          html
          timeToRead
          frontmatter {
            category
            title
            description
            keywords
            lang
            idd
            lang
            date(formatString: "MMM DD, YYYY")
            path
            banner {
              childImageSharp {
                fluid(maxWidth: 1440) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            mainImage {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
