import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const MobileNav = ({ open, closeNav, children }) => {
  const navClass = classnames('mobile-side-menu', { open });

  return (
    <nav className={navClass}>
      <div
        onClick={closeNav}
        onKeyPress={closeNav}
        aria-label="mobile-menu"
      />
      {children}
    </nav>
  );
};

MobileNav.propTypes = {
  open: PropTypes.bool.isRequired,
  closeNav: PropTypes.func,
  children: PropTypes.element.isRequired,
};

MobileNav.defaultProps = {
  closeNav: () => {},
};

export default MobileNav;
