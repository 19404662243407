import React from 'react';
import PropTypes from 'prop-types';
import Navigation from './Navigation/index';

const Header = ({ onToggleMenu }) => (
  <header id="header" className="alt">
    <Navigation onClick={onToggleMenu} />
  </header>
);

Header.propTypes = {
  onToggleMenu: PropTypes.func.isRequired,
};

export default Header;
