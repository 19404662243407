import 'lazysizes';
import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { withTranslation } from 'gatsby-plugin-react-i18next';

import { ChooseDocviserSection } from '../components/Sections/ChooseDocviserSection';
import HomeThumbnail from '../assets/images/homePage/hero.png';
import AccordionComponent from '../components/Accordion';
import Layout from '../components/GatsbyLayout';
import Helmet from '../components/Helmet';

const renderSectionSubtitle = (title, span, afterTitle) => (
  <div className="title-container">
    <h2 className="home-title">
      {title}
      <span className={'home-title text-gradient'}>{span}.</span>
    </h2>
    <div className="description">{afterTitle}</div>
  </div>
);

const renderHelmet = t => (
  <Helmet
    title={t('seoTitle')}
    description={t('seoDescription')}
    keywords={t('seoKeywords')}
    thumbnail={HomeThumbnail}
    canonical
    canonicalPage="about-us"
  />
);

const renderSection = ({ subtitle, content, contentClassNames }) => (
  <div className="main__section">
    {subtitle}
    <div className={classnames('main__section__content', contentClassNames)}>{content}</div>
  </div>
);

renderSection.propTypes = {
  subtitle: PropTypes.string,
  content: PropTypes.shape({}),
  contentClassNames: PropTypes.string,
};

renderSection.defaultProps = {
  subtitle: null,
  content: null,
  contentClassNames: null,
};

const renderFAQSection = t => {
  return renderSection({
    subtitle: renderSectionSubtitle(t('sectionTitle'), t('sectionTitle2'), t('sectionDescription')),
    content: (
      <div style={{ display: 'flex', justifyContent: 'center', width: '100%', flexDirection: 'column', gap: '2.4rem' }}>
        <AccordionComponent items={t('questions', { returnObjects: true })} />
      </div>
    ),
    contentClassNames: 'content faq-content',
  });
};

const faq = ({ location, t }) => {
  return (
    <Layout location={location}>
      <div className="home">
        {renderHelmet(t)}
        <h1 className="show-for-seo">{t('faq.name')}</h1>
        <div className="main faq">
          <div className="main main__inner">
            {renderFAQSection(t)}
            <ChooseDocviserSection t={t} />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default withTranslation()(faq);

faq.propTypes = {
  location: PropTypes.shape({}).isRequired,
  data: PropTypes.shape({}),
};

faq.defaultProps = {
  data: null,
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { ns: { in: ["index", "faq"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
