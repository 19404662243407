import React from 'react';

const Testimonial = props => {
  const { quote, imgSrc, author, position } = props;
  return (
    <div className="testimonial">
      <div className={'testimonials-text'}>{quote}</div>
      <div className={'features-container'}>
        <img className="testimonial--image" src={imgSrc} alt={author} title={author} width="48" height="48" />
        <div>
          <h4 className="testimonial--title">{author}</h4>
          <h4 className="testimonial--details">{position}</h4>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
