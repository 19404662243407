import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import { uniq } from 'lodash';

import Loadable from 'react-loadable';
import Layout from '../components/GatsbyLayout';

import SearchEmpty from '../assets/images/blog/search-empty-state.svg';
import SearchIcon from '../assets/images/blog/search.svg';
import CloseIcon from '../assets/images/blog/close.svg';
import BlogBG from '../assets/images/blog/blog-banner.png';
import Pagination from '../components/Pagination';
import Search from '../components/Search';
import Tags from '../components/Tags';

import { arrayIncludesSubstring } from '../util/arrays';
import { stringIncludesSubstring } from '../util/string';
import detectWebCrawler from '../util/web-crawler';
import { getPathSegmentAfterCertainOne } from '../util/url';
import Article from '../components/Article';
import classNames from 'classnames';
import { withTranslation } from 'gatsby-plugin-react-i18next';
import { resetLanguage } from '../util/lang';

const Loading = () => <h3>Loading...</h3>;

const FooterComponent = Loadable({
  loader: () => import('../components/Footer'),
  loading: Loading,
});

const CATEGORY = ['All', 'Software', 'Healthcare', 'News'];
const CATEGORY_INCOMPLETE = ['Software', 'Healthcare', 'News'];
const ALL_CATEGORIES = 'All';

const numberOfPostsPerPageUpdate = 6;
// The breakpoints where the bottom limit for infinite pagination will increase
const mediumBreakpoint = 1106;
const smallBreakpoint = 480;

class BlogList extends Component {
  static getAllUniqueCategories = () => {
    return uniq(CATEGORY);
  };

  static shouldRenderPost(edge, searchTerm, activeCategories, lng) {
    const {
      node: {
        frontmatter: { title, description, tags, category, lang },
      },
    } = edge;

    const nameFoundInTitle = stringIncludesSubstring(searchTerm, title);
    const nameFoundInDescription = stringIncludesSubstring(searchTerm, description);
    const nameFoundInTags = arrayIncludesSubstring(searchTerm, tags);

    const searchTermFound = nameFoundInTitle || nameFoundInDescription || nameFoundInTags;
    const categoryToSearch = arrayIncludesSubstring(ALL_CATEGORIES, activeCategories)
      ? CATEGORY_INCOMPLETE
      : activeCategories;
    const categoryFound = arrayIncludesSubstring(category, categoryToSearch);

    return categoryFound && searchTermFound && lang === lng && !!edge.node.frontmatter.date;
  }

  static renderHelmet = t => {
    let siteURL = '{BlogList.renderHelmet()}';
    const title = t('blogList.seoTitle');

    if (typeof window !== 'undefined' && window.location) {
      siteURL = window.location.origin;
    }

    return (
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta property="og:title" content={title} />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={BlogBG} />
        <meta property="og:url" content={`${siteURL}/blog/`} />
        <meta property="og:image:secure_url" content={BlogBG} />
        <meta name="description" content={t('blogList.seoDescription')} />
        <meta name="keywords" content={t('blogList.seoKeyWords')} />
        <meta name="twitter:image" content={BlogBG} />
        <link rel="canonical" href={`${siteURL}/blog/`} />
      </Helmet>
    );
  };

  constructor(props) {
    super(props);

    const {
      data: { all, paginated },
    } = this.props;
    const allCategories = BlogList.getAllUniqueCategories();

    const category = getPathSegmentAfterCertainOne('category');
    const crawler = detectWebCrawler();
    const posts = crawler ? paginated.edges : all.edges;
    const categoriesParsed = {};

    allCategories.forEach(tag => {
      categoriesParsed[tag] = {
        active: tag === ALL_CATEGORIES,
        name: tag,
      };
    });

    this.state = {
      activeCategories: [ALL_CATEGORIES],
      category,
      crawler,
      numberOfPostsToShow: numberOfPostsPerPageUpdate,
      posts,
      searchTerm: '',
      showMore: false,
      categories: categoriesParsed,
      ticking: false,
      showSearchInput: false,
    };
  }

  onResizeWindow = () => {
    const screenWidth = typeof window !== 'undefined' && window.innerWidth;
    const medium = 860;

    if (screenWidth < medium) {
      return this.setState({ showSearchInput: true, searchTerm: '' });
    } else {
      return this.setState({ showSearchInput: false, searchTerm: '' });
    }
  };

  componentDidMount() {
    const { category } = this.state;
    if (typeof window !== 'undefined') {
      if (localStorage.getItem('blog')) {
        localStorage.setItem('blog', '');
        const offset = localStorage.getItem('scrollOffset');
        if (offset && !category) {
          this.setState({ numberOfPostsToShow: Number(localStorage.getItem('noOfPosts')) });
          setTimeout(() => {
            if (typeof window !== 'undefined') {
              window.scroll({ top: Number(offset), left: 0, behavior: 'smooth' });
            }
          });
        }
      }

      localStorage.setItem('scrollOffset', '');
      localStorage.setItem('noOfPosts', '');

      window.addEventListener('scroll', this.handleScroll);
      window.addEventListener('resize', this.onResizeWindow);

      resetLanguage();

      this.onResizeWindow();
    }
  }

  handleSearchInputChange = event => {
    const {
      target: { value },
    } = event;

    this.setState({ searchTerm: value });
  };

  clearSearchText = () => {
    this.setState({ searchTerm: '' });
  };

  handleScroll = () => {
    const { ticking } = this.state;

    if (!ticking) {
      this.setState({ ticking: true });

      requestAnimationFrame(() => this.updateInfinitePagination());
    }
  };

  onTagClick = key => {
    const { categories } = this.state;
    const categoriesToReturn = {};

    Object.keys(categories).forEach(key => {
      categoriesToReturn[key] = {
        active: false,
        name: key,
      };
    });

    const isAllCategoriesButton = categories[key].name === ALL_CATEGORIES;
    const resetTagClick = isAllCategoriesButton ? true : !categories[key].active;

    this.setState({
      categories: {
        ...categoriesToReturn,
        [key]: {
          ...categories[key],
          active: resetTagClick,
        },
        [ALL_CATEGORIES]: {
          ...categories[ALL_CATEGORIES],
          active: isAllCategoriesButton ? resetTagClick : !resetTagClick,
        },
      },
      activeCategories: resetTagClick ? [key] : [ALL_CATEGORIES],
    });
    if (typeof window !== 'undefined') {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  loadMorePosts = () => {
    const { numberOfPostsToShow } = this.state;

    this.setState({
      numberOfPostsToShow: numberOfPostsToShow + numberOfPostsPerPageUpdate,
      showMore: true,
    });
  };

  updateInfinitePagination() {
    const { showMore, numberOfPostsToShow } = this.state;

    if (typeof window !== 'undefined') {
      const distanceToBottom = document.documentElement.offsetHeight - (window.scrollY + window.innerHeight);
      const screenWidth = window.innerWidth;

      let distanceToBottomLimit = 800;

      const screenSizeBetweenMediumAndSmall = screenWidth < mediumBreakpoint && screenWidth >= smallBreakpoint;
      const screenSizeSmall = screenWidth < smallBreakpoint;

      if (screenSizeBetweenMediumAndSmall) {
        distanceToBottomLimit = 800;
      } else if (screenSizeSmall) {
        distanceToBottomLimit = 1250;
      }

      const scrollArrivedToBottom = distanceToBottom < distanceToBottomLimit;

      if (showMore && scrollArrivedToBottom) {
        this.setState({ numberOfPostsToShow: numberOfPostsToShow + numberOfPostsPerPageUpdate });
      }

      this.setState({ ticking: false });
    }
  }

  renderTags() {
    const { categories } = this.state;

    return <Tags all={categories} onTagClick={this.onTagClick} />;
  }

  renderClassicPagination() {
    const {
      pageContext: { currentPage, numPages },
    } = this.props;

    return <Pagination currentPage={currentPage} numPages={numPages} path="blog" />;
  }

  renderSearchInput() {
    const { searchTerm } = this.state;

    return (
      <Search onChange={this.handleSearchInputChange} onClearIconClick={this.clearSearchText} value={searchTerm} />
    );
  }

  renderNoResults(t) {
    const { searchTerm } = this.state;

    const forSearchTerm = searchTerm && `" ${searchTerm}"`;

    const description = `${t('blogList.noResultsDescription1')} ${forSearchTerm} ${t(
      'blogList.noResultsDescription2'
    )}`;

    return (
      <div className="blog-list__no-results align-center">
        <img src={SearchEmpty} alt="No results" title="No results" />
        <div className="blog-list__no-results--title">Oops!</div>
        <div className="blog-list__no-results--description">{description}</div>
      </div>
    );
  }

  renderMorePosts = (showLoadMoreButton, windowSize) => {
    switch (true) {
      case windowSize <= 1024:
        return showLoadMoreButton && typeof window !== 'undefined' && window.pageYOffset >= 960 && this.loadMorePosts();
      case windowSize < 1450:
        return (
          showLoadMoreButton && typeof window !== 'undefined' && window.pageYOffset >= 1200 && this.loadMorePosts()
        );
      case windowSize < 1600:
        return showLoadMoreButton && typeof window !== 'undefined' && window.pageYOffset >= 800 && this.loadMorePosts();
      default:
        return showLoadMoreButton && typeof window !== 'undefined' && window.pageYOffset >= 600 && this.loadMorePosts();
    }
  };

  render() {
    const { location, t } = this.props;
    const { searchTerm, showSearchInput, showMore, numberOfPostsToShow, posts, crawler, activeCategories, category } =
      this.state;
    const lang = t('lang');

    const FilteredPosts = posts
      .filter(edge => BlogList.shouldRenderPost(edge, searchTerm, activeCategories, lang))
      .map(edge => (
        <Article
          key={edge.node.id}
          title={edge.node.frontmatter.title}
          category={edge.node.frontmatter.category}
          readingTime={edge.node.timeToRead}
          date={edge.node.frontmatter.date}
          path={edge.node.frontmatter.path}
          imageFluid={edge.node.frontmatter.mainImage.childImageSharp.fluid}
          imageFluidMobile={
            edge.node.frontmatter.mobileImage && edge.node.frontmatter.mobileImage.childImageSharp.fluid
          }
        />
      ));

    const PostsToRender = FilteredPosts.slice(0, numberOfPostsToShow);

    const showLoadMoreButton = FilteredPosts.length > PostsToRender.length && !showMore;

    const blogListClass = PostsToRender.length ? 'blog-list__blogs' : '';

    return (
      <Layout location={location}>
        {BlogList.renderHelmet(t)}
        <div className="main blog-list">
          <div className="main__inner">
            <div className="blog-list__header">
              <h1 className="title">{t('blogList.title')}</h1>
              <div className="blog-list__section">
                {this.renderTags()}
                <div className="search">
                  {showSearchInput && this.renderSearchInput()}
                  <img
                    src={showSearchInput ? CloseIcon : SearchIcon}
                    alt="icon"
                    onClick={() => this.setState({ showSearchInput: !showSearchInput, searchTerm: '' })}
                    className={'search__image'}
                  />
                </div>
              </div>
            </div>
            <div className={classNames('post__latest-items articles', blogListClass)}>
              {PostsToRender.length ? PostsToRender : this.renderNoResults(t)}
              {this.renderMorePosts(showLoadMoreButton, typeof window !== 'undefined' && window.innerWidth)}
              {crawler && this.renderClassicPagination()}
            </div>
            <h3 className={'show-for-seo'} />
          </div>
        </div>
      </Layout>
    );
  }
}

BlogList.propTypes = {
  data: PropTypes.shape({}).isRequired,
  location: PropTypes.shape({}).isRequired,
  pageContext: PropTypes.shape({}).isRequired,
};

export default withTranslation()(BlogList);

export const blogListQuery = graphql`
  query blogListQuery($skip: Int!, $limit: Int!, $language: String!) {
    locales: allLocale(filter: { ns: { in: ["index"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    paginated: allMarkdownRemark(sort: { frontmatter: { date: DESC } }, limit: $limit, skip: $skip) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          timeToRead
          frontmatter {
            title
            description
            tags
            date(formatString: "MMMM DD, YYYY")
            path
            mainImage {
              childImageSharp {
                fluid(maxWidth: 500) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    all: allMarkdownRemark(sort: { frontmatter: { date: DESC } }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          timeToRead
          frontmatter {
            title
            description
            tags
            category
            popular
            lang
            date(formatString: "MMMM DD, YYYY")
            path
            mainImage {
              childImageSharp {
                fluid(maxWidth: 500) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
