import React from 'react';
import { isoCertificationsObject } from '../../data/Certifications';

export const CertificationsSection = ({ t }) => {
  return (
    <div className="title-container title-container-big-gap">
      <div className="title-container">
        <h2 className="home-title">
          {t('home.isoCertificationsSection.title')}
          <span className="text-gradient">.</span>
        </h2>
        <div className="description">{t('home.isoCertificationsSection.subtitle')}</div>
      </div>
      <div className={'features-container features-container-big-gap'}>
        {isoCertificationsObject.map(item => (
          <a id={item.pdf} href={item.pdf} target="_blank" key={t(item.title)} className="card card-small">
            <div>
              <img src={item.icon} alt={item.icon} className="iso-img iso-img-first" />
              <img src={item.icon2} alt={t(item.title)} className="iso-img iso-img-second" />
            </div>
            <div className={'title-container'}>
              <div>{t(item.subtitle)}</div>
              <div className="description">
                {t(item.title)}
                <br />
                {t(item.date)}
              </div>
            </div>
          </a>
        ))}
      </div>
    </div>
  );
};
