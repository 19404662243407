import React from 'react';
import Subtitle from '../Subsection/Subtitle';

const PrivacyRo = () => {
  return (
    <>
      <Subtitle title={'Politica de Confidentialitate'} withoutLine titleType="h1" />
      <h2 style={{ display: 'none' }}>Docviser Oncology - Politica de Confidențialitate</h2>
      <div className={'terms-and-privacy'}>
        <h3 className={'font-big font-weight-light-bold paragrah'}>1. Despre nota de informare</h3>
        Docviser S.R.L. este societatea care deţine Aplicaţia în a cărei bază de date Utilizatorii inserează date
        personale şi date cu caracter special.
        <br />
        <br />
        În această notă de informare vă explicăm modul în care are loc prelucrarea datelor cu caracter personal de către
        Operatorii Asociaţi (Docviser şi Utilizatorii) și în care ne asigurăm că toate datele cu caracter personal sunt
        prelucrate responsabil și în conformitate cu legislația privind protecția datelor cu caracter personal care este
        aplicabilă.
        <br />
        <br />
        Pentru scopurile acestei note de informare, Docviser este operator de date cu caracter personal, alături de
        Utilizatorii Aplicaţiei. În acest sens, puteți găsi mai jos informații cu privire la identitatea și datele
        noastre de contact. De asemenea, tot mai jos puteți găsi datele de contact ale responsabilului cu protecția
        datelor din cadrul Docviser.
        <br />
        <br />
        Noi, cei de la Docviser, precum și partenerii noștri, luăm foarte în serios respectul față de datele cu caracter
        personal.
        <br />
        <br />
        Conformitatea cu legislația privind protecția datelor cu caracter personal și bunele practici în domeniu, precum
        și asigurarea unui climat de transparență, siguranță și încredere este o prioritate pentru noi.
        <br />
        <br />
        Conţinutul acestei note de informare este pur informativ şi nu afectează drepturile pe care vi le oferă
        legislaţia. Vom face tot posibilul pentru a vă facilita exercitarea acestora.
        <h3 className={'font-big font-weight-light-bold paragrah'}>2. Cine suntem noi</h3>
        „Aplicaţia” dezvoltată de către Docviser pune la dispozitia persoanelor şi instituţiilor implicate în sistemul
        medical o solutie care să simplifice şi să încurajeze colaborarea dintre aceştia în cadrul proceselor de tratare
        a unor anumite afecţiuni medicale.
        <br />
        <br />
        Accesul în Aplicaţie este oferit cu titlu gratuit clinicilor medicale şi, implicit, persoanelor care activează
        în cadrul acestor clinici, astfel încât să fie facilitată elaborarea de statistici cu implicaţie exclusiv
        medicală, de diagnostic şi de tratament a afecţiunilor vizate, în scopul eficientizării rezultatelor obţinute,
        în sensul însănătoşirii pacienţilor ori al îmbunătăţirii stării lor de sănătate, pe baza schemelor de tratament
        aplicate şi inserate în Aplicaţie.
        <br />
        <br />
        În acest sens, Docviser a făcut echipă cu experți medicali pentru a construi o platformă adaptată la nevoile
        furnizorilor de servicii de îngrijire a pacienților cu cancer, servicii medicale de înaltă calitate care
        presupun prelucrarea datelor cu caracter personal ale Pacientului și în raport de care ambele entități au rol de
        Operator în sensul art. 24 și art. 26 din Regulamentul 679/2016 (GDPR).
        <br />
        <br />
        Dacă aveţi orice comentarii, sugestii, întrebări sau preocupări cu privire la orice informaţii din această notă
        sau cu privire la orice alte aspecte referitoare la prelucrarea datelor pe care o realizăm, vă rugăm să nu
        ezitaţi să contactaţi oricând responsabilul nostru cu protecţia datelor. În funcție de preferințe, ne puteți
        contacta prin oricare dintre canalele de comunicare de mai jos.
        <br />
        <br />
        Întreaga noastră echipă va depune toate eforturile rezonabile pentru a ne asigura că va răspunde cât mai rapid
        şi complet.
        <br />
        <br />
        Datele noastre de contact:
        <br />
        <br />
        Denumire completă: DOCVISER S.A
        <br />
        <br />
        Adresă sediu social: Strada Dinu Lipatti, nr. 25, sat Feleacu, Comuna Feleacu, jud. Cluj
        <br />
        <br />
        In cazul in care intampinati probleme de confidentialitate va rugam sa ne scrieti imediat pe adresa:
        contact@docviser.io
        <h3 className={'font-big font-weight-light-bold paragrah'}>
          3. Ce date sunt prelucrate prin intermediul Aplicaţiei
        </h3>
        Datele cu caracter personal pe care le vom prelucra sunt datele obținute direct de la Pacient sau inserate de
        către Utilizatori în Aplicaţie și includ următoarele categorii de date:
        <br />
        <br />
        Date medicale (date cu caracter personal sensibile), cum ar ﬁ: simptome; boli anterioare; analize și medicamente
        administrate în trecut; grupă sangvină; analize şi alte servicii pe care le accesaţi la unul dintre Utilizatori;
        rezultatele analizelor; tratamentul prescris sau administrat; medicul pe care l-aţi accesat; recomandări
        medicale; date din dosarul medical din spital, inclusiv date despre istoricul medical al familiei Pacientului;
        date biometrice.
        <br />
        <br />
        Detalii personale, cum ar ﬁ: nume; prenume; sex; data naşterii/ vârsta; cetăţenia; cod numeric personal (CNP);
        restul informațiilor din actul de identitate.
        <br />
        <br />
        Detalii de contact, cum ar ﬁ: adresă de domiciliu / reşedinţă; număr de telefon mobil/ﬁx; număr de fax; adresă
        de email.
        <br />
        <br />
        Detalii referitoare la asigurări, cum ar ﬁ: calitatea de asigurat/ neasigurat, asigurator (in cazul asigurărilor
        private).
        <br />
        <br />
        Opinii şi viziuni (pot include date sensibile), cum ar ﬁ: orice opinii şi viziuni pe care le transmiteţi prin
        intermediul Aplicaţiei sau orice opinii şi viziuni pe care le postaţi public despre noi pe reţelele de
        socializare (social media) sau pe care le faceţi cunoscute pe alte canale publice.
        <br />
        <br />
        Date de localizare, cum ar ﬁ: locul sau zona în care vă aﬂaţi sau v-aţi aﬂat într-un anumit interval de timp
        (dacă utilizaţi aplicaţiile noastre cu opţiuni de localizare şi aveţi activate aceste opţiuni).
        <br />
        <br />
        După cum reiese din lista de mai sus, este posibil să ne fie furnizate inclusiv informații cu privire la alte
        persoane – de exemplu, istoricul medical al rudelor care suferă de aceeași condiție medicală ş.a.
        <br />
        <br />
        Atunci când acestea se referă la persoane identiﬁcate sau pe care le putem identiﬁca, vom trata aceste
        informații drept date cu caracter personal ale acelor persoane şi le vom acorda şi lor protecţia necesară.
        <br />
        <br />
        Vom respecta însă cu strictețe obligația de păstrare a secretului profesional (inclusiv a secretului medical) pe
        care o avem față de persoana iniţial vizată și nu vom informa aceste persoane despre această procesare pentru a
        respecta obligația noastră de secret profesional (inclusiv medical) faţă de persoana iniţial vizată.
        <br />
        <h3 className={'font-big font-weight-light-bold paragrah'}>4. Sursa din care primim datele</h3>
        Informaţiile cu privire la Pacienţi sunt inserate în baza de date a Aplicaţiei de către Utilizatori
        (Clinici/doctori etc.), obţinute în esenţă atunci când se semnează un contract de prestări servicii sau când se
        procedează la o internare, ori când folosiţi serviciile medicale într-una dintre clinici/laboratoare partenere
        ale Docviser.
        <br />
        <br />
        Se încearcă în mod constant păstrarea datelor Pacienţilor cât mai corectă şi la zi.
        <br />
        <br />
        În acest scop, Utilizatorii desfăşoară în mod continuu o companie de strângere şi actualizare a datelor, astfel
        încât acestea să fie actuale şi corecte.
        <h3 className={'font-big font-weight-light-bold paragrah'}>
          5. Temeiurile în baza cărora datele sunt prelucrate
        </h3>
        În principiu Docviser nu prelucrează date cu caracter personal, altele decât datele sensibile, cu privire la
        Pacienţi, în scop statistic.
        <br />
        <br />
        Totuşi, putem prelucra datele dumneavoastră pentru îndeplinirea obligaţiilor noastre de arhivare, a obligaţiilor
        de a comunica unor autorităţi publice, la cerere, anumite informaţii sau a altor obligaţii legale.
        <br />
        <br />
        De asemenea, sunt cazuri în care prelucrăm datele în temeiul interesului nostru legitim, de exemplu cazul în
        care vă comunicăm informatii necesare pentru protejarea stării de sănătate sau, având in vedere multitudinea
        clinicilor, laboratoarelor sau a spitalelor partenere, pentru a facilita accesarea serviciilor medicale.
        <br />
        <br />
        Temeiurile pentru care prelucrăm datele sensibile cu caracter personal
        <br />
        <br />
        Având în vedere scopul pentru care a fost creată Aplicaţia, este normal să colectăm sau să prelucrăm si date
        sensibile cu caracter personal.
        <br />
        <br />
        Vom realiza aceste prelucrări pe baza următoarelor temeiuri juridice:
        <br />
        <br />
        Atunci când prelucrarea este necesară în scopuri legate de stabilirea unui diagnostic medical, de furnizarea de
        asistenţă medicală sau a unui tratament medical sau scopuri de cercetare ştiinţifică ori în scopuri statistice.
        <br />
        <br />
        Putem prelucra datele și în situații de urgență medicală sau alte situații în care persoanele vizate se află în
        incapacitate (ﬁzică sau juridică) de a consimți la prelucrare, putem prelucra datele sensibile în scopul
        protejării intereselor vitale ale persoanei vizate (sau ale unei alte persoane ﬁzice).
        <br />
        <br />
        În cazuri urgente, poate ﬁ necesar să prelucrăm datele medicale din motive de interes public în domeniul
        sănătăţii publice; de exemplu: protecţia împotriva ameninţărilor transfrontaliere grave la adresa sănătăţii,
        asigurarea de standarde ridicate de calitate şi siguranţă a asistenţei medicale şi a medicamentelor sau
        dispozitivelor medicale, în temeiul legislaţiei Uniunii Europene sau a României. În situația în care apar
        diferende pe care nu le putem soluționa împreună pe cale amiabilă, este posibil să prelucrăm datele sensibile
        (de exemplu, rezultatele analizelor medicale în baza cărora s-a decis un anumit diagnostic) pentru constatarea,
        exercitarea sau apărarea unui drept al nostru în instanță.
        <h3 className={'font-big font-weight-light-bold paragrah'}>
          6. Scopurile pentru care prelucrăm date cu caracter personal
        </h3>
        Scopurile pentru care prelucrăm date cu caracter personal sunt următoarele:
        <br />
        <br />
        Prestarea de servicii medicale de către Utilizatori. Prestarea serviciilor medicale; înregistrarea serviciilor
        medicale prestate; comunicarea cu privire servicii; informarea cu privire la rezultatele serviciilor prestate.
        <br />
        <br />
        Comunicări de marketing. Comunicarea cu persoana vizată prin orice mijloace (de exemplu, e-mail, telefon mobil
        sau ﬁx, mesaje telefonice (SMS-uri), poştă, mesaje transmise pe platformele de socializare sau în persoană)
        noutăţi privind serviciile medicale disponibile în cadrul unităţilor Utilizatorului, abonare la newsletter sau
        furnizarea alte informaţii care ar putea interesa persoana vizată.
        <br />
        <br />
        Gestionarea sistemelor noastre de comunicaţii şi IT (tehnologia informaţiei). Gestionarea sistemelor noastre de
        comunicaţii; gestionarea securităţii noastre IT; realizarea auditurilor de securitate asupra reţelelor noastre
        IT, emiterea de rapoarte către institutiile abilitate sau repararea unor erori de sistem.
        <br />
        <br />
        Îndeplinirea obligaţiilor noastre legale. Îndeplinirea obligaţiilor noastre legale cu privire la arhivare,
        sănătate, securitate, ţinerea evidenţelor și a altor obligații pe care legislația ni le impune.
        <br />
        <br />
        Soluţionarea disputelor. Formularea de cereri şi de apărări înaintea autorităţilor publice şi a altor entităţi
        care soluţionează dispute.
        <br />
        <br />
        Îmbunătăţirea produselor şi a serviciilor. Identiﬁcarea potenţialelor probleme cu privire la serviciile noastre
        existente în vederea îmbunătățirii acestora (inclusiv prin realizarea de audituri); testarea îmbunătăţirilor
        realizate asupra serviciilor noastre sau a noilor noastre servicii; soluţionarea sesizărilor dumneavoastră.
        <br />
        <br />
        Scopurile prevăzute de art. 89 alineatul (1) din RGPD. În scopuri de arhivare în interes public, în scopuri de
        cercetare științifică sau istorică ori în scopuri statistice.
        <h3 className={'font-big font-weight-light-bold paragrah'}>7. Cui vor fi divulgate datele </h3>
        Ca regulă, nu divulgăm datele către alte companii, organizaţii sau persoane din orice ţară, însă în anumite
        situații este totuşi posibil să divulgăm datele către alte persoane ﬁzice sau juridice.
        <br />
        <br />
        Din păcate nu este posibil să oferim la acest moment informații precise cu privire la identitatea exactă a
        tuturor destinatarilor posibili ai datelor dumneavoastră.
        <br />
        <br />
        Încercăm însă să ﬁm cât de transparenți și speciﬁci cu putință, iar în continuare vom prezenta categoriile de
        astfel de destinatari:
        <br />
        <br />
        Autorități publice din orice domeniu, din România sau din străinătate (în special autorități publice în domeniul
        sănătății din România: Casa Națională de Asigurări de Sănătate, Ministerul Sănătății și altele) – la cererea
        acestora sau din inițiativa noastră, în acord cu legislația aplicabilă.
        <br />
        <br />
        Contabili, auditori, avocați și alți consultanți profesionali externi ai noștri, din România sau din străinătate
        – aceștia vor ﬁ obligați printr-o lege sau prin contractul încheiat cu noi sau cu altă companie din grupul
        nostru să păstreze conﬁdențialitatea datelor dumneavoastră.
        <br />
        <br />
        Persoane ﬁzice sau juridice care acționează ca persoane împuternicite pentru Operatorii Asociaţi, în diverse
        domenii, care vor fi obligaţi însă să respecte cerințele legislației care vă protejează drepturile.
        <br />
        <br />
        Între Utilizatorii Aplicaţiei (medicii colaboratori şi alți furnizori de servicii medicale); ﬁecare dintre
        aceştia ﬁind obligaţi prin lege sau prin contractul încheiat cu noi să păstreze conﬁdenţialitatea datelor.
        <br />
        <br />
        Atunci când folosim o persoană ﬁzică sau juridică drept persoană împuternicită pentru prelucrarea datelor cu
        caracter personal, ne vom asigura că aceasta a încheiat un act scris cu noi prin care își asumă, printre alte
        obligații pe care legislația protecției datelor cu caracter personal le prevede, obligațiile de a (i) prelucra
        datele cu caracter personal numai potrivit instrucțiunilor noastre scrise pe care i le-am furnizat în prealabil
        și de a (ii) implementa efectiv măsuri pentru protejarea conﬁdențialității și asigurarea securității datelor cu
        caracter personal.
        <br />
        <br />
        Ne vom asigura, de asemenea, că actul scris dintre noi și persoana împuternicită prevede pentru aceasta cel
        puțin toate celelalte obligații pe care le prevede legislația aplicabilă cu privire la protecția datelor cu
        caracter personal.
        <h3 className={'font-big font-weight-light-bold paragrah'}>8. Cât timp vom stoca datele</h3>
        Datele vor fi păstrate într-o formă care permite identificarea persoanelor vizate pe o perioadă care nu
        depășește perioada necesară îndeplinirii scopurilor în care sunt prelucrate datele, mai exact datele vor fi
        stocate pe perioada necesară atingerii scopului medical, respectiv în scopurile prevăzute de art. 89 din RGPD
        (arhivare în interes public, scopuri de cercetare științifică ori în scopuri statistice).
        <h3 className={'font-big font-weight-light-bold paragrah'}>9. Securitatea datelor</h3>
        Ne dăm tot interesul pentru a preveni accesul neautorizat şi modiﬁcarea, divulgarea sau distrugerea neautorizată
        a datelor pe care le prelucrăm.
        <br />
        <br />
        În special, am implementat următoarele măsuri tehnice și organizatorice de asigurare a securității datelor cu
        caracter personal:
        <br />
        <br />
        Aplicația este securizată la nivel de server, la nivel de client și la nivel de aplicație web & mobilă, iar
        datele din baza de date sunt criptate.
        <h3 className={'font-big font-weight-light-bold paragrah'}>
          10. Care sunt drepturile persoanelor vizate şi cum pot fi exercitate
        </h3>
        Succint, drepturile persoanei vizate sunt următoarele:
        <br />
        <br />
        Dreptul de acces la date. Aveţi dreptul de a obţine accesul la datele dumneavoastră pe care le prelucrăm sau le
        controlăm sau la copii ale acestora; aveţi, de asemenea, dreptul de a obţine de la noi informaţii cu privire la
        natura, prelucrarea şi divulgarea acestor date.
        <br />
        <br />
        Dreptul la rectiﬁcarea datelor. Aveţi dreptul de a obţine rectiﬁcarea inexactităţilor datelor dumneavoastră pe
        care le prelucrăm sau le controlăm.
        <br />
        <br />
        Dreptul la ştergerea datelor („dreptul de a ﬁ uitat”). Aveţi dreptul de a obţine de la noi ştergerea datelor
        dumneavoastră pe care le prelucrăm sau le controlăm, sub rezerva menţiunii conform căreia dacă datele sunt
        colectate în scopuri de arhivare în interes public, în scopuri de cercetare științifică sau istorică ori în
        scopuri statistice, dacă dreptul la ştergerea datelor este susceptibil să facă imposibilă sau să afecteze în mod
        grav realizarea obiectivelor prelucrării, Operatorul se poate opune ştergerii datelor deja colectate.
        <br />
        <br />
        Dreptul la restricţionarea prelucrării datelor. Aveţi dreptul de a restricţiona prelucrarea datelor
        dumneavoastră pe care le prelucrăm sau le controlăm.
        <br />
        <br />
        Dreptul de a obiecta. Aveţi dreptul de a obiecta la prelucrarea datelor dumneavoastră de către noi sau în numele
        nostru.
        <br />
        <br />
        Dreptul la portabilitatea datelor. Aveţi dreptul de a obţine transferul către un alt operator al datelor
        dumneavoastră pe care le prelucrăm sau le controlăm.
        <br />
        <br />
        Dreptul la retragerea consimţământului. În situaţiile în care prelucrăm datele dumneavoastră în temeiul
        consimţământului dumneavoastră, aveţi dreptul de a vă retrage consimţământul; puteți face aceasta în orice
        moment, cel puţin la fel de uşor cum ne-aţi acordat iniţial consimţământul; retragerea consimțământului nu va
        afecta legalitatea prelucrării datelor dumneavoastră pe care am realizat-o înainte de retragere.
        <br />
        <br />
        Dreptul de a depune o plângere la autoritatea de supraveghere. Aveţi dreptul de a depune o plângere la
        autoritatea de supraveghere a prelucrării datelor cu caracter personal cu privire la prelucrarea datelor
        dumneavoastră de către noi sau în numele nostru.
        <br />
        <br />
        Pentru a exercita unul sau mai multe dintre aceste drepturi (inclusiv dreptul de a vă retrage consimțământul,
        atunci când vă prelucrăm datele pe baza acestuia) sau pentru a adresa orice întrebare despre oricare dintre
        aceste drepturi sau orice prevedere din această notă de informare sau despre oricare alte aspecte ale
        prelucrării datelor dumneavoastră de către noi, vă rugăm să folosiţi oricând doriţi detaliile de contact din
        secţiunea 2 de mai sus (Datele noastre de contact).
        <br />
        <br />
        Vom încerca să răspundem cât mai rapid şi complet tuturor întrebărilor şi preocupărilor dumneavoastră şi să
        facilităm exercitarea drepturilor dumneavoastră.
        <h3 className={'font-big font-weight-light-bold paragrah'}>
          11. Când devine aplicabilă această Notă de informare
        </h3>
        Această Notă de informare se aplică în legătură cu toate demersurile şi datele presupuse de utilizarea
        Aplicaţiei Docviser, exclusiv în scopul menţionat.
        <h3 className={'font-big font-weight-light-bold paragrah'}>12. Modificări ale Notei de informare</h3>
        Este posibil să modiﬁcăm această notă de informare odată la un anumit timp. În astfel de cazuri, nu vom reduce
        drepturile pe care le aveți cu privire la datele dumneavoastră prin modiﬁcările pe care le-am putea aduce
        acestei note.
        <h3 className={'font-big font-weight-light-bold paragrah'}>13. Semnificaţia unor termeni</h3>
        Autoritatea de supraveghere a prelucrării datelor cu caracter personal: o autoritate publică independentă care,
        potrivit legii, are atribuţii referitoare la supravegherea respectării legislaţiei protecţiei datelor cu
        caracter personal. În România, această autoritatea de supraveghere a prelucrării datelor cu caracter personal
        este Autoritatea Naţională de Supraveghere a Prelucrării Datelor cu Caracter Personal (ANSPDCP).
        <br />
        <br />
        Categorii speciale de date cu caracter personal (date cu caracter personal sensibile/ date sensibile): datele cu
        caracter personal care: dezvăluie originea rasială sau etnică, opiniile politice, confesiunea religioasă sau
        convingerile ﬁlozoﬁce sau apartenența la sindicate; datele genetice; datele biometrice pentru identiﬁcarea unică
        a unei persoane ﬁzice; datele privind sănătatea, viața sexuală sau orientarea sexuală a unei persoane ﬁzice.
        <br />
        <br />
        Date cu caracter personal: orice informații privind o persoană ﬁzică identiﬁcată sau identiﬁcabilă (denumită
        „persoană vizată”). O persoană ﬁzică este identiﬁcabilă dacă poate ﬁ identiﬁcată, direct sau indirect, în
        special prin referire la un element de identiﬁcare, de exemplu: nume, număr de identiﬁcare, date de localizare,
        identiﬁcator online, unul/mai multe elemente speciﬁce, proprii identității ﬁzice, ﬁziologice, genetice, psihice,
        economice, culturale sau sociale a respectivei persoane. Astfel, de exemplu, în noțiunea de date cu caracter
        personal sunt incluse următoarele: numele și prenumele; adresa de domiciliu sau de reședință; adresa de email;
        numărul de telefon; codul numeric personal (CNP); diagnosticul stabilit (sunt date sensibile); date genetice
        (sunt date sensibile); date bimoetrice (sunt date sensibile); date de geolocație. Categoriile de date cu
        caracter personal despre dumneavoastră pe care le prelucrăm sunt enumerate mai sus.
        <br />
        <br />
        Operator: persoana ﬁzică sau juridică care decide de ce (în ce scop) şi cum (prin ce mijloace) sunt prelucrate
        datele cu caracter personal. Potrivit legii, răspunderea pentru respectarea legislaţiei referitoare la datele cu
        caracter personal revine în primul rând operatorului. În relația cu dumneavoastră, Utilizatorii Aplicaţiei,
        precum şi Docviser, sunt operatori.
        <br />
        <br />
        Persoană împuternicită: orice persoană ﬁzică sau juridică care prelucrează date cu caracter personal în numele
        operatorului, alta decât angajaţii operatorului.
        <br />
        <br />
        Persoană vizată: persoana ﬁzică la care se referă (căreia îi „aparțin”) anumite date cu caracter personal. În
        relația cu noi (operatorul), dumneavoastră sunteți persoana vizată.
        <br />
        <br />
        Prelucrare a datelor cu caracter personal: orice operațiune/set de operațiuni efectuată/efectuate asupra datelor
        cu caracter personal sau asupra seturilor de date cu caracter personal, cu sau fără utilizarea mijloacelor
        automatizate; de exemplu: colectarea, înregistrarea, organizarea, structurarea, stocarea, adaptarea sau
        modiﬁcarea, extragerea, consultarea, utilizarea, divulgarea prin transmitere, diseminarea sau punerea la
        dispoziție în orice alt mod, alinierea sau combinarea, restricționarea, ștergerea sau distrugerea respectivelor
        date cu caracter personal/seturi de date cu caracter personal. Acestea sunt doar exemple. Practic, prelucrarea
        înseamnă orice operaţiune asupra datelor cu caracter personal, indiferent dacă prin mijloace automate sau
        manuale.
        <br />
        <br />
        Utilizator: orice persoană care accesează Aplicaţia/Platforma on-line, care îşi creează cont pe
        Aplicaţie/Platforma on-line sau care doreşte să utilizeze în orice mod sau utilizează efectiv Serviciul,
        respectiv Clinica/ Policlinica/ Spitalul/ Super-doctorul/ Doctorul/ Farmacistul/ Asistentul etc.
        <h3 className={'font-big font-weight-light-bold paragrah'}>
          14. Politica de Confidențialitate a Datelor Utilizatorilor Google
        </h3>
        <p>
          Aplicația solicită acces la Google Calendar pentru a gestiona intrările de consultații fără a modifica
          evenimentele personale. Creează, modifică sau șterge intrări doar cu confirmarea dvs. explicită. Datele sunt
          utilizate exclusiv pentru a sincroniza Google Calendar cu calendarul din aplicație pentru vizualizare,
          notificări și programare. Nicio dată nu este transferată sau accesibilă oamenilor. Toate acțiunile respectă
          Politica de confidențialitate a datelor Google API Services.
        </p>
        <p>
          Pentru mai multe informații, vă rugăm să consultați Politica privind Datele Utilizatorilor Serviciilor API
          Google:{' '}
          <a href="https://developers.google.com/terms/api-services-user-data-policy">
            https://developers.google.com/terms/api-services-user-data-policy
          </a>
          .
        </p>
      </div>
    </>
  );
};

export default PrivacyRo;
