import React from 'react';
import { useLocation } from '@gatsbyjs/reach-router';
import { FormControl, MenuItem, Select } from '@mui/material';
import { Link, useI18next } from 'gatsby-plugin-react-i18next';

const LanguageSwitcher = ({ isMobile }) => {
  const data = useI18next();
  const { i18n } = data;
  const languages = {
    en: 'en',
    ro: 'ro',
  };
  const containerStyle = isMobile ? { width: '15rem', marginTop: '1rem' } : { width: '5.2rem', marginLeft: '2rem' };
  const location = useLocation();
  const currentPath = location.pathname.replaceAll('/ro', '');

  return (
    <FormControl variant="standard" style={containerStyle}>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={i18n.resolvedLanguage}
        label="Language"
        style={{ height: 'auto', fontSize: '1.3rem', paddingLeft: '1rem' }}
        MenuProps={{
          PaperProps: {
            sx: {
              li: {
                fontSize: isMobile ? '2.6rem !important' : '1.3rem',
              },
            },
          },
        }}
      >
        <MenuItem value={languages.en}>
          <Link to={currentPath} language={languages.en} style={{ textDecoration: 'none' }}>
            EN
          </Link>
        </MenuItem>
        <MenuItem value={languages.ro}>
          <Link to={currentPath} language={languages.ro} style={{ textDecoration: 'none' }}>
            RO
          </Link>
        </MenuItem>
      </Select>
    </FormControl>
  );
};

export default LanguageSwitcher;
