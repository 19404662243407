import React from "react";

import NewQuoteForm from "../NewQuoteForm";
import DateIcon from '../../assets/images/webinar/date.svg';
import TimeIcon from '../../assets/images/webinar/time.svg';

const SignUp = () => {
  return (
    <div id="contact" className={'sign-up'}>
      <div className="card main__inner main background">
        <div className="card-info background">
          <h1 className="card-info-mini-title">
            ÎNSCRIERI
          </h1>
          <h2 className="card-info-title font-display title"  tabIndex="0">
            Vrei să afli mai multe?
          </h2>
          <h3 className="card-info-subtitle">
            Completează formularul cu toate informațiile
            <br />
            necesare și ești înscris automat. Webinarul este
            <br />
            100% gratuit, tot ce trebuie să faci este să te prezinți.
          </h3>
  
          <h4 className="card-info-details details">
            <div className="card-info-email date" >
              <img src={DateIcon} alt="Mail" title="Mail" width="32" height="32" />
              <div className="card-info-email-contact details-text">17 Noiembrie</div>
            </div>
            <div className="card-info-phone">
              <img src={TimeIcon} alt="Phone" title="Phone" width="32" height="32" />
              <h4 className="card-info-phone-number font-title2 details-text">
                <div>17:00</div>
              </h4>
            </div>
          </h4>
        </div>
      
        <div className="card-form form-background">
          <div className="contact-form">
            <NewQuoteForm isWebinar/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
