import crest from '../assets/images/iso/crest.jpg';
import ISO_13485 from '../assets/pdfs/Iso_13485.pdf';
import UASIcon from '../assets/images/iso/URS_UAS_ISO.svg';
import ISO_27001 from '../assets/pdfs/Iso_27001.pdf';
import trust from '../assets/pdfs/Certificate_of_Trust_DocViser.pdf';
import ISO13485Icon from '../assets/images/iso/URS_UAS_ISO13485.svg';
import cyber from '../assets/images/iso/cyberthreatdefense_logo.jpeg';
import ISO27001Icon from '../assets/images/iso/URS_UAS_ISO-IEC27001.svg';

export const isoCertificationsObject = [
  {
    icon: ISO27001Icon,
    icon2: UASIcon,
    subtitle: 'home.isoCertificationsSection.ISO27001.subtitle',
    title: 'home.isoCertificationsSection.ISO27001.title',
    date: 'home.isoCertificationsSection.ISO27001.date',
    pdf: ISO_27001,
  },
  {
    icon: crest,
    icon2: cyber,
    subtitle: 'home.isoCertificationsSection.trust.subtitle',
    title: 'home.isoCertificationsSection.trust.title',
    date: 'home.isoCertificationsSection.trust.date',
    pdf: trust,
  },
  {
    icon: ISO13485Icon,
    icon2: UASIcon,
    subtitle: 'home.isoCertificationsSection.ISO13485.subtitle',
    title: 'home.isoCertificationsSection.ISO13485.title',
    date: 'home.isoCertificationsSection.ISO13485.date',
    pdf: ISO_13485,
  },
];
