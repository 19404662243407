import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import classnames from 'classnames';
import BlankA from './BlankHyperlink';

export default class Button extends Component {
  renderButtonType = type => {
    const { goTo, title, icon, classNames, internalLink, boldTitle, onClick } = this.props;

    switch (type) {
      case 'primary-dark-button':
        return (
          <button
            onClick={onClick}
            className={classnames('button-primary-dark font-weight-normal no-shadow', classNames)}
          >
            <Link target="_self" to={goTo}>
              {title}
            </Link>
          </button>
        );
      case 'primary-small-dark':
        return (
          <button
            onClick={onClick}
            className={classnames('button-primary-small-dark font-weight-normal no-shadow', classNames)}
          >
            <Link target="_self" to={goTo}>
              {title}
            </Link>
          </button>
        );
      case 'primary-dark':
        return (
          <Link target="_self" to={goTo} className={classnames('button-primary-dark font-button-bold', classNames)}>
            {title}
          </Link>
        );
      case 'primary-dark-blank':
        return (
          <BlankA target="_self" href={goTo} className={classnames('button-primary-dark font-button-bold', classNames)}>
            {title}
          </BlankA>
        );
      case 'primary-large-dark':
        return (
          <Link
            target="_self"
            to={goTo}
            className={classnames('button-primary-large-dark font-button-large-bold', classNames)}
          >
            {title}
          </Link>
        );
      case 'primary-light':
        return (
          <Link target="_self" to={goTo} className="button-primary-light font-button-bold font-weight-light-bold">
            {title}
          </Link>
        );
      case 'primary-large-light':
        return internalLink === true ? (
          <Link
            target="_self"
            to={goTo}
            className={classnames('button-primary-large-light font-button-large-bold', classNames)}
          >
            {title}
          </Link>
        ) : (
          <BlankA
            target="_self"
            href={goTo}
            className={classnames('button-primary-large-light font-button-large-bold', classNames)}
          >
            {title}
          </BlankA>
        );
      case 'primary-large-light-link':
        return (
          <Link
            target="_self"
            to={goTo}
            className={classnames('button-primary-large-light font-button-large-bold', classNames)}
          >
            {title}
          </Link>
        );
      case 'primary-large-dark-link':
        return (
          <Link target="_self" to={goTo} className="button-primary-large-dark font-button-large-bold">
            {title}
          </Link>
        );
      case 'primary-large-light-icon':
        return (
          <BlankA
            target="_self"
            href={goTo}
            className={classnames('button-primary-large-light-icon font-button-large-bold icon', icon, classNames)}
          >
            &nbsp;
            {title}
          </BlankA>
        );
      case 'primary-large-light-icon-2':
        return (
          <BlankA
            target="_self"
            href={goTo}
            className={classnames('button-primary-large-light-icon font-button-large-bold icon', classNames)}
          >
            &nbsp;
            <i className={icon} />
            {title}
          </BlankA>
        );
      case 'primary-large-dark-icon':
        return (
          <BlankA
            target="_self"
            href={goTo}
            className={classnames('button-primary-dark-icon font-button-large-bold', icon)}
          >
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <img src={icon} alt="button_icon" title="ButtonIcon" className="button-icon-large" />
              {title}
            </div>
          </BlankA>
        );
      case 'secondary':
        return (
          <Link target="_self" to={goTo} className="button-secondary-default font-button-bold">
            {title}
          </Link>
        );
      case 'secondary-icon':
        return (
          <Link target="_self" to={goTo} className="button-secondary-default font-button-bold font-weight-light-bold">
            <img src={icon} alt="button_icon" title="ButtonIcon" className="button-icon" />
            {title}
          </Link>
        );
      case 'secondary-icon-external':
        return (
          <BlankA
            target="_self"
            href={goTo}
            className="button-secondary-default font-button-bold font-weight-light-bold"
          >
            <img src={icon} alt="button_icon" title="ButtonIcon" className="button-icon" />
            {title}
          </BlankA>
        );
      case 'secondary-large':
        return (
          <Link
            target="_self"
            to={goTo}
            className={classnames('button-secondary font-title2 font-weight-light-bold', classNames)}
          >
            {title}
          </Link>
        );
      case 'secondary-large-icon':
        return (
          <Link
            target="_self"
            to={goTo}
            className={classnames('button-secondary font-title2 font-weight-light-bold', classNames)}
          >
            <img src={icon} alt="button_icon" title="ButtonIcon" className="button-icon-large" />
            {title}
          </Link>
        );
      case 'secondary-large-icon-external':
        return (
          <BlankA
            target="_self"
            href={goTo}
            className={classnames('button-secondary font-title2 font-weight-light-bold', classNames)}
          >
            <img src={icon} alt="button_icon" title="ButtonIcon" className="button-icon-large" />
            {title}
          </BlankA>
        );
      case 'mobile-store-icon':
        return (
          <BlankA
            target="_self"
            href={goTo}
            className={classnames('mobile-store font-title2 font-weight-light', classNames)}
          >
            <img src={icon} alt="button_icon" title="ButtonIcon" className="mobile-store-button-icon" />
            <div className={'mobile-store-text'}>
              <div className="font-body3">{title}</div>
              <div className="font-body1-smaller-lineheight font-weight-bold">{boldTitle}</div>
            </div>
          </BlankA>
        );
      default:
        return null;
    }
  };

  render() {
    const { type } = this.props;

    return this.renderButtonType(type);
  }
}

Button.propTypes = {
  goTo: PropTypes.string,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  icon: PropTypes.string,
  classNames: PropTypes.string,
  internalLink: PropTypes.bool,
  boldTitle: PropTypes.string,
  onClick: PropTypes.func,
};

Button.defaultProps = {
  icon: '',
  classNames: '',
  internalLink: false,
  boldTitle: '',
};
