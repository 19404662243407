import React from 'react';
import PropTypes from 'prop-types';

// Using target="_blank" without rel="noopener noreferrer" is a security risk.
// See https://mathiasbynens.github.io/rel-noopener.

const BlankHyperlink = (props) => {
  const { children } = props;

  return (
    <a {...props} target="_blank" rel="noopener noreferrer">{children}</a>
  );
};

BlankHyperlink.propTypes = {
  children: PropTypes.node,
};

BlankHyperlink.defaultProps = {
  children: null,
};

export default BlankHyperlink;
