import AlexandruIliescu from '../assets/images/homePage/testimonials/alexandru_iliescu.jpg';
import IoanAdrianBuda from '../assets/images/homePage/testimonials/ioan_adrian_buda.jpg';
import GeaninaMiron from '../assets/images/homePage/testimonials/geanina_miron.jpg';

export const testimonials = [
  {
    author: 'Alexandru Iliescu',
    position: 'home.testimonialsSection.alexIliescu.position',
    imageSrc: AlexandruIliescu,
    quote: 'home.testimonialsSection.alexIliescu.text',
  },
  {
    author: 'Ioan Adrian Buda',
    position: 'home.testimonialsSection.ioanAdrianBuda.position',
    imageSrc: IoanAdrianBuda,
    quote: 'home.testimonialsSection.ioanAdrianBuda.text',
  },
  {
    author: 'Geanina Miron',
    position: 'home.testimonialsSection.geaninaMiron.position',
    imageSrc: GeaninaMiron,
    quote: 'home.testimonialsSection.geaninaMiron.text',
  },
];
