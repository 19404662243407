export const Colors = {
  Background: '#F7F9FF',
  PageBackground: '#f8f9ff',
  BackgroundBlue: '#EBF0FF',
  FooterBlue: '#EBF0FF',
  Accent: '#FF5748',
  LightGrey: '#82899B',
  DarkGrey: '#5D5F64',
  Grey: '#676C7A',
  Black: '#3C3F48',
  White: '#fff',
  Primary: '#2962FF',
  PrimaryDark: '#1C316B',
  Border: '#e1e4e8',
  Danger: '#d45e5e',
};
