import React from 'react';
import PropTypes from 'prop-types';

import Layout from '../components/GatsbyLayout';
import Helmet from '../components/Helmet';
import { graphql } from 'gatsby';
import { withTranslation } from 'gatsby-plugin-react-i18next';
import TermsEn from '../components/Terms/TermsEn';
import TermsRo from '../components/Terms/TermsRo';

const TermsAndConditions = ({ location, t }) => {
  const Template = () => (t('content.template') === 'TermsEn' ? <TermsEn /> : <TermsRo />);
  return (
    <>
      <Helmet title={t('seoTitle')} description={t('seoDescription')} keywords={t('seoKeywords')} canonical />
      <Layout location={location}>
        <div className="main post">
          <div className="main__inner">
            <div className="main__section">
              <div id="article-post-content" className="post__content">
                <Template />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

TermsAndConditions.propTypes = {
  location: PropTypes.shape({}).isRequired,
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { ns: { in: ["terms-and-conditions"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default withTranslation()(TermsAndConditions);
