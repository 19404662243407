import React from "react";

const Subjects = () => {
  const leftSubjects = [{
    number: '01.',
    title: 'Ce este Docviser?',
    description: 'Introducere'
  }, {
    number: '02.',
    title: 'Flow-ul Clinicii',
    description: 'De la doctor la pacient'
  }, {
    number: '03.',
    title: 'Protocoale de tratament',
    description: 'Care sunt și de unde vin'
  }, {
    number: '04.',
    title: 'Tumor Boards ',
    description: 'Crearea de tumor boards & video meeting'
  }];
  
  const rightSubjects = [{
    number: '05.',
    title: 'Chat-ul Clinicii',
    description: 'Comunicarea între doctori în clinică'
  }, {
    number: '06.',
    title: 'Reports',
    description: 'Cum generăm date din clinică?'
  }, {
    number: '07.',
    title: 'Pașii Următori',
    description: 'Ce vă mai pregătește Docviser?'
  }, {
    number: '08.',
    title: 'Q&A',
    description: 'Sesiune de întrebări'
  }];
  
  const Row = ({number, title, description}) => (
    <div className={'row'}>
      <div className={'row-number'}>{number}</div>
      <div>
        <div className={'row-title'}>{title}</div>
        <div className={'row-description'}>{description}</div>
      </div>
    </div>
  );
  
  return (
    <div className="main main__inner">
      <div className="subjects">
        <div className="subjects-section-tag">SUBIECTE</div>
        <h2 className="subjects-title">Despre Ce Se Va Discuta?</h2>
        <h3 className="subjects-subtitle">
          Programul webinar-ului
        </h3>
        
        <div className={'rows'}>
          <div className={'rows-left'}>
            {leftSubjects.map((element, index) => (
              <Row key={`subject_${index}`} number={element.number} title={element.title} description={element.description}/>
             ))}
          </div>
          <div>
            {rightSubjects.map((element, index) => (
              <Row key={`subject_${index}`} number={element.number} title={element.title} description={element.description}/>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Subjects;
