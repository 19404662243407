import Ai from '../assets/images/homePage/features/ai.png';
import Prescription from '../assets/images/homePage/features/prescription.png';
import Scheduler from '../assets/images/homePage/features/scheduler.png';
import Pharmanurse from '../assets/images/homePage/features/pharmanurse.png';
import Management from '../assets/images/homePage/features/management.png';
import TumorBoards from '../assets/images/homePage/features/tumorBoards.png';
import Chat from '../assets/images/homePage/features/chat.png';
import Protocols from '../assets/images/homePage/features/protocols.png';

export const features = [
  { icon: Ai, title: 'ai', link: '/patient-management/assistant' },
  { icon: Prescription, title: 'prescription', link: '/patient-management/prescription' },
  { icon: Scheduler, title: 'scheduler', link: '/patient-management/scheduler' },
  { icon: Pharmanurse, title: 'pharmanurse', link: '/patient-management/pharmnurse' },
];

export const featuresProduct = [
  { icon: Scheduler, title: 'scheduler', link: '/patient-management/scheduler' },
  { icon: Pharmanurse, title: 'pharmanurse', link: '/patient-management/pharmnurse' },
  { icon: Management, title: 'management', link: '/patient-management/patientManagement' },
  { icon: Ai, title: 'ai', link: '/patient-management/assistant' },
  { icon: Prescription, title: 'prescription', link: '/patient-management/prescription' },
  { icon: TumorBoards, title: 'tumorBoards', link: '/patient-management/tumorboard' },
  { icon: Chat, title: 'chat', link: '/patient-management/chat' },
  { icon: Protocols, title: 'protocols', link: '/patient-management/protocols' },
];

export const links = {
  patientManagement: require.context('../assets/images/features/patientManagement/', true),
  scheduler: require.context('../assets/images/features/scheduler/', true),
  pharmnurse: require.context('../assets/images/features/pharmnurse/', true),
  assistant: require.context('../assets/images/features/assistant/', true),
  prescription: require.context('../assets/images/features/prescription/', true),
  tumorboard: require.context('../assets/images/features/tumorboard/', true),
  chat: require.context('../assets/images/features/chat/', true),
  protocols: require.context('../assets/images/features/protocols/', true),
};

export const validIds = [
  'patientManagement',
  'scheduler',
  'pharmnurse',
  'assistant',
  'prescription',
  'tumorboard',
  'chat',
  'protocols',
];
