/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
const $ = require('jquery');

const ReactDOM = require('react-dom');

const loadableReady = require('@loadable/component').loadableReady;

window.$ = $;
window.jQuery = $;

exports.replaceHydrateFunction = () => {
  return (element, container, callback) => {
    loadableReady(() => {
      ReactDOM.render(element, container, callback);
    });
  };
};
