import React from "react";

import UsersIcon from '../../assets/images/webinar/users.svg';
import ScheduleIcon from '../../assets/images/webinar/schedule.svg';
import FastForwardIcon from '../../assets/images/webinar/fast_forward.svg';
import SyncIcon from '../../assets/images/webinar/sync.svg';

const Card = ({icon, title, description}) => (
  <div className={'card'}>
    <div className={'icon-wrapper'}>
      <img src={icon} alt={'icon'} className={'icon'}/>
    </div>
    <div>
      <div className={'title'}>{title}</div>
      <div className={'description'}>{description}</div>
    </div>
  </div>
);

const Testimonials = () => {
  
  return (
    <div className="home__main-color-dark-container">
      <div className={'main testimonials'}>
        <Card
          icon={UsersIcon}
          title={'Managementul Pacientilor'}
          description={'Docviser poate reduce până la 80% din timpul petrecut între doctori, farmaciști și asistenți până la finalizarea administrării unui tratament.'}
        />
        <Card
          icon={ScheduleIcon}
          title={'Timpi de așteptare'}
          description={'Docviser poate reduce până la 40% din timpul de așteptare al pacienților în clinică.'}
        />
        <Card
          icon={SyncIcon}
          title={'Calcule sigure'}
          description={'Formulele folosite în cadrul Docviser garantează că 100% din calcule sunt corecte și sigure.'}
        />
        <Card
          icon={FastForwardIcon}
          title={'Rapid și eficient'}
          description={'Prescrierea tratamentelor este cu până la 50% mai rapidă și mai ușoară cu Docviser.'}
        />
      </div>
    </div>
  );
};

export default Testimonials;
