import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

import HomeImage from '../assets/images/homePage/hero.png';

const excludeHTMLTags = text => text && text.toString().replace(/<\/?[^>]+(>|$)/g, ' ');

const excludeNonNumericAndNonLetterChars = text => text && text.toString().replace(/[^0-9a-z-A-Z ]/g, '');

const clearText = text => excludeNonNumericAndNonLetterChars(excludeHTMLTags(text));

const getSiteUrl = () => {
  if (process.env.NODE_ENV === 'production') {
    return 'https://docviser.io';
  }

  return 'https://qa.docviser.io/';
};

const CustomHelmet = ({ title, description, keywords, author, thumbnail, canonical, canonicalPage, noFollow }) => {
  const formattedDescription = clearText(excludeHTMLTags(description.toString().trim()));
  const formattedTitle = excludeHTMLTags(title.toString().trim());
  const formattedKeyWords = excludeHTMLTags(keywords.toString().trim());
  let formattedThumbnail = thumbnail || HomeImage;
  let siteURL = getSiteUrl();

  if (typeof window !== 'undefined' && window.location) {
    siteURL = window.location.origin;
    if (formattedThumbnail.indexOf('http') === -1) {
      formattedThumbnail = siteURL + formattedThumbnail;
    }
  }

  if (!formattedTitle) {
    console.warn('Docviser-SEO: title is missing for this page');
  }

  if (!formattedDescription) {
    console.warn('Docviser-SEO: description is missing for this page');
  }

  if (!formattedKeyWords) {
    console.warn('Docviser-SEO: keywords are missing for this page');
  }

  const formattedUrl = canonical && canonicalPage ? `${siteURL}/${canonicalPage}/` : siteURL;

  return (
    <Helmet>
      <html lang="en" />
      <title>{formattedTitle}</title>
      {author && <meta name="author" content={author} />}
      {formattedDescription && <meta name="description" content={formattedDescription} />}
      {formattedKeyWords && <meta name="keywords" content={formattedKeyWords} />}
      {canonical && !canonicalPage && <link rel="canonical" href={siteURL} />}
      {canonical && canonicalPage && <link rel="canonical" href={`${siteURL}/${canonicalPage}/`} />}
      {noFollow === true && <meta name="robots" content="noindex,nofollow" />}
      <meta property="og:title" content={formattedTitle} />
      <meta property="og:type" content="website" />
      <meta property="og:image" content={formattedThumbnail} />
      <meta property="og:url" content={formattedUrl} />
      <meta property="og:image:secure_url" content={formattedThumbnail} />
      <meta property="og:description" content={formattedDescription} />
      <meta property="og:image:width" content="700" />
      <meta property="og:image:height" content="440" />
      <meta property="og:locale" content="en" />
      <meta name="twitter:site" content="@docviserio" />
      <meta name="twitter:title" content={formattedTitle} />
      <meta name="twitter:description" content={formattedDescription} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:image" content={formattedThumbnail} />
    </Helmet>
  );
};

CustomHelmet.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  keywords: PropTypes.string,
  author: PropTypes.string,
};

CustomHelmet.defaultProps = {
  title: '',
  description: null,
  keywords: '',
  author: null,
};

export default CustomHelmet;
