import React from 'react';
import PropTypes from 'prop-types';

import Layout from '../components/GatsbyLayout';
import Subtitle from '../components/Subsection/Subtitle';
import Helmet from '../components/Helmet';
import { graphql } from 'gatsby';
import { withTranslation } from 'gatsby-plugin-react-i18next';

const PrivacyPolicy = ({ location }) => {
  return (
    <>
      <Helmet
        title={'Privacy Policy - Mobile | Docviser'}
        noFollow={true}
        description={
          'Privacy Policy document for doctors and patients within docviser oncology platform more specific to the mobile app'
        }
        keywords={'privacy policy, mobile, oncology'}
        canonical
      />
      <Layout location={location}>
        <div className="main post">
          <div className="main__inner">
            <div className="main__section">
              <div id="article-post-content" className="post__content">
                <Subtitle title={'Privacy Policy - Mobile app'} withoutLine titleType="h1" />
                <h2 style={{ display: 'none' }}>Docviser Oncology Mobile - Privacy Policy</h2>
                <div className={'terms-and-privacy'}>
                  <h3 className={'font-big font-weight-light-bold paragrah'}>1. About the information notice</h3>
                  Docviser S.R.L. is the company that owns the Application in whose database Users enter personal data
                  and special categories of data.
                  <br />
                  <br />
                  In this document, when the notion of User is used to designate the joint controller of personal data,
                  it will not include the patients.
                  <br />
                  <br />
                  In this information notice we explain how the Joint Controllers (Docviser and Users) carry out the
                  processing of personal data and how we ensure that all personal data is processed responsibly and in
                  accordance with the applicable personal data protection legislation.
                  <br />
                  <br />
                  For the purposes of this information notice, Docviser is a personal data controller, together with the
                  Users of the Application. In this regard, you can find information on our identity and contact details
                  below. You can also find the contact details of Docviser's Data Protection Officer below.
                  <br />
                  <br />
                  We at Docviser, as well as our partners, take very seriously the respect for personal data.
                  <br />
                  <br />
                  Complying with data protection legislation and best practices and ensuring transparency, security and
                  trust is a priority for us.
                  <br />
                  <br />
                  The content of this information notice is purely informative and does not affect your rights under the
                  law. We will do our best to make it easier for you to exercise those rights.
                  <h3 className={'font-big font-weight-light-bold paragrah'}>2. Who we are</h3>
                  The "Application" developed by Docviser provides individuals and institutions involved in the medical
                  system with a solution that simplifies and encourages collaboration between them in the processes of
                  treating certain medical conditions.
                  <br />
                  <br />
                  Access to the Application is offered to medical clinics and, implicitly, to the people working in
                  these clinics, so as to facilitate the development of statistics with medical implications
                  exclusively, of diagnosis and treatment of the conditions concerned, with the aim of making the
                  results obtained more efficient, in terms of improving Patients' health, on the basis of the treatment
                  schemes applied and inserted in the Application.
                  <br />
                  <br />
                  In this regard, Docviser has teamed up with medical experts to build a platform tailored to the needs
                  of healthcare providers for cancer patients, high quality medical services that involve the processing
                  of Patients' personal data and in relation to which both entities have the role of Controller within
                  the meaning of Art. 24 and Art. 26 of Regulation 679/2016 (GDPR).
                  <br />
                  <br />
                  If you have any comments, suggestions, questions or concerns about any of the information in this
                  notice or about any other issues relating to the data processing we carry out, please do not hesitate
                  to contact our Data Protection Officer at any time. Depending on your preference, you can contact us
                  through any of the communication channels below.
                  <br />
                  <br />
                  Our entire team will make every reasonable effort to ensure that we respond as quickly and completely
                  as possible.
                  <br />
                  <br />
                  Our contact details:
                  <br />
                  <br />
                  Full name: DOCVISER S.R.L.
                  <br />
                  <br />
                  Head office address: Dinu Lipatti Street, no. 25, Feleacu village, Feleacu, Cluj County
                  <br />
                  <br />
                  In case you have any issues concerning the protection of your personal data, you can contact us at the
                  following email address: contact@docviser.io
                  <h3 className={'font-big font-weight-light-bold paragrah'}>
                    3. What data is processed through the Application
                  </h3>
                  The personal data we will process is data obtained directly from the Patient or entered by Users into
                  the Application and includes the following categories of data:
                  <br />
                  <br />
                  Medical data (sensitive personal data), such as: symptoms; previous illnesses; medical tests and
                  medication administered in the past; blood type; tests and other data that may be obtained about one
                  of the Users; test results; treatment prescribed or administered; the doctor you have accessed;
                  medical recommendations; data from the medical record in the hospital, including data about the
                  Patient's family medical history; biometric data.
                  <br />
                  <br />
                  Personal details, such as: first name; surname; gender; date of birth/age; nationality; personal
                  identification number; other information from the ID card.
                  <br />
                  <br />
                  Contact details, such as: home/residence address; mobile number; telephone; fax number; email address.
                  <br />
                  <br />
                  Insurance details such as: insured/uninsured status, insurer (in case of private insurance).
                  <br />
                  <br />
                  Location data, such as: the place or area where you are or have been within a certain time frame (if
                  you use our Application with location options and have these options enabled).
                  <br />
                  <br />
                  As shown in the list above, we may also be provided with information about other people - for example,
                  the medical history of relatives suffering from the same medical condition.
                  <br />
                  <br />
                  If this information relates to identifiable individuals or individuals we can identify, we will treat
                  this information as personal data of those individuals and will provide them with the necessary
                  protection.
                  <br />
                  <br />
                  We will, however, strictly comply with our obligation of professional secrecy (including medical
                  secrecy) to the original data subject and will not inform these individuals of this processing in
                  order to comply with our obligation of professional (including medical) secrecy to the original data
                  subject.
                  <h3 className={'font-big font-weight-light-bold paragrah'}>4. Data source</h3>
                  Patient information is entered into the Application's database by Users (Clinics/doctors etc.),
                  obtained essentially when signing a service contract or when making a medical admission, or when using
                  medical services in one of Docviser's partner clinics/laboratories.
                  <br />
                  <br />
                  We constantly try to keep patient data as accurate and up-to-date as possible.
                  <br />
                  <br />
                  To this end, Users continually collect and update the data so that it is current and accurate.
                  <h3 className={'font-big font-weight-light-bold paragrah'}>
                    5. The legal grounds on which the data are processed
                  </h3>
                  In general, Docviser does not process personal data, other than data about Patients for statistical
                  purposes.
                  <br />
                  <br />
                  However, we may process your data for the fulfilment of our archiving obligations, obligations to
                  communicate certain information to public authorities upon request or other legal obligations.
                  <br />
                  <br />
                  There are also cases in which we process data on the basis of our legitimate interest, for example
                  when we communicate information to you necessary to protect your health or, given the multitude of
                  partner clinics, laboratories or hospitals, to facilitate access to medical services.
                  <br />
                  <br />
                  The grounds on which we process sensitive personal data.
                  <br />
                  <br />
                  We will carry out such processing on the following legal grounds:
                  <br />
                  <br />
                  Where the processing is necessary for purposes related to establishing a medical diagnosis, providing
                  medical care or treatment or for scientific research purposes or for statistical purposes.
                  <br />
                  <br />
                  We may also process data in situations of medical emergency or other situations where data subjects
                  are not able (physically or legally) to consent to the processing, we may process sensitive data in
                  order to protect the vital interests of the data subject (or another natural person).
                  <br />
                  <br />
                  In urgent cases, it may be necessary to process medical data for reasons of public interest in the
                  field of public health; for example: protection against serious cross-border threats to health,
                  ensuring high standards of quality and safety of healthcare and medicines or medical devices under
                  European Union or Romanian law.
                  <h3 className={'font-big font-weight-light-bold paragrah'}>
                    6. Purposes for which we process personal data
                  </h3>
                  The purposes for which we process personal data are the following:
                  <br />
                  <br />
                  The provision of medical services by Users. Provision of medical services; registration of medical
                  services provided; communication about services; information about the results of services provided.
                  <br />
                  <br />
                  Marketing communications. Communicating with the data subject by any means (e.g. email, mobile or
                  telephone, messages (SMS), mail, messages sent on social media platforms or in person) regarding news
                  about medical services available at the User's facilities, subscribing to newsletters or providing
                  other information that may be of interest to the data subject.
                  <br />
                  <br />
                  Managing our communications and IT (information technology) systems. Managing our communications
                  systems; managing our IT security; conducting security audits on our IT networks, issuing reports to
                  the relevant institutions or fixing system errors
                  <br />
                  <br />
                  Fulfilling our legal obligations. Fulfilling our legal obligations with respect to archiving, health,
                  safety, record keeping and other obligations that the law requires of us.
                  <br />
                  <br />
                  Dispute resolution. Submitting claims and defences to public authorities and other dispute resolution
                  entities.
                  <br />
                  <br />
                  Improving products and services. Identifying potential problems with our existing services with a view
                  to improving them (including through audits); testing improvements to our services or new services;
                  dealing with your complaints.
                  <br />
                  <br />
                  The purposes set out in Article 89(1) of the GDPR. For archiving purposes in the public interest, for
                  scientific or historical research purposes or for statistical purposes.
                  <h3 className={'font-big font-weight-light-bold paragrah'}>7. To whom the data will be disclosed </h3>
                  As a rule, we do not disclose data to other companies, organisations or individuals in any country,
                  but in certain situations we may disclose data to other natural or legal persons.
                  <br />
                  <br />
                  Unfortunately it is not possible at this time to provide precise information on the exact identity of
                  all possible recipients of your data.
                  <br />
                  <br />
                  However, we try to be as transparent and specific as possible, and we will set out below the
                  categories of such recipients:
                  <br />
                  <br />
                  Public authorities in any field, in Romania or abroad (in particular public health authorities in
                  Romania: National Health Insurance House, Ministry of Health and others) - at their request or on our
                  initiative, in accordance with applicable law.
                  <br />
                  <br />
                  Accountants, auditors, lawyers and other external professional consultants of ours, from Romania or
                  abroad - they will be obliged by law or by contract concluded with us or another company in our group
                  to keep your data confidential.
                  <br />
                  <br />
                  Individuals or legal entities acting as representatives of the Joint Controllers, in various fields,
                  who will however be obliged to comply with the legal requirements protecting your rights.
                  <br />
                  <br />
                  Between the Users of the Application (collaborating doctors and other health care providers); each of
                  whom is bound by law or by contract concluded with us to maintain the confidentiality of the data.
                  <br />
                  <br />
                  When we allow a natural or legal person to act as a processor of personal data, we will ensure that
                  they have entered into a written agreement with us whereby they assume, among other obligations under
                  data protection law, obligations to (i) process personal data only in accordance with our written
                  instructions that we have provided to them in advance and (ii) effectively implement measures to
                  protect the confidentiality and ensure the security of personal data.
                  <br />
                  <br />
                  We will also ensure that the written agreement between us and the processor sets out for them at least
                  all other obligations that are required by the applicable law relating to the protection of personal
                  data.
                  <h3 className={'font-big font-weight-light-bold paragrah'}>8. How long we will store the data</h3>
                  The data will be kept in a form which allows identification of data subjects for no longer than is
                  necessary for the purposes for which the data are processed, more specifically the data will be stored
                  for as long as it is necessary to achieve the medical purpose and for the purposes set out in Article
                  89 GDPR (archiving in the public interest, scientific research purposes or for statistical purposes).
                  <h3 className={'font-big font-weight-light-bold paragrah'}>9. Data security</h3>
                  We make every effort to prevent unauthorised access to and unauthorised modification, disclosure or
                  destruction of the data we process.
                  <br />
                  <br />
                  In particular, we have implemented the following technical and organisational measures to ensure the
                  security of personal data:
                  <br />
                  <br />
                  The application is secured at server level, client level and mobile application level and the data in
                  the database is encrypted.
                  <h3 className={'font-big font-weight-light-bold paragrah'}>
                    10. What are the rights of data subjects and how can they be exercised
                  </h3>
                  Briefly, the data subject's rights are the following:
                  <br />
                  <br />
                  Right of access to data. You have the right to obtain access to your data that we process or control,
                  or copies thereof; you also have the right to obtain information from us about the nature, processing
                  and disclosure of such data.
                  <br />
                  <br />
                  The right to rectification of data. You have the right to obtain rectification of inaccuracies in your
                  data that we process or control.
                  <br />
                  <br />
                  The right to erasure of data ("right to be forgotten"). You have the right to obtain the erasure of
                  your data that we process or control, provided that if the data are collected for archiving purposes
                  in the public interest, for scientific or historical research purposes or for statistical purposes, if
                  the right to erasure is likely to make impossible or seriously affect the achievement of the purposes
                  of the processing, the Controller may object to the erasure of the data already collected.
                  <br />
                  <br />
                  Right to restrict data processing. You have the right to restrict the processing of your data that we
                  process or control.
                  <br />
                  <br />
                  Right to object. You have the right to object to the processing of your data by us or on our behalf.
                  <br />
                  <br />
                  Right to data portability. You have the right to obtain the transfer of your data that we process or
                  control to another controller.
                  <br />
                  <br />
                  Right to withdraw consent. In situations where we process your data on the basis of your consent, you
                  have the right to withdraw your consent; you may do so at any time, at least as easily as you
                  originally gave us your consent; withdrawing your consent will not affect the lawfulness of the
                  processing of your data that we carried out prior to the withdrawal.
                  <br />
                  <br />
                  The right to lodge a complaint with the supervisory authority. You have the right to lodge a complaint
                  with the supervisory authority for the processing of personal data about the processing of your data
                  by us or on our behalf.
                  <br />
                  <br />
                  To exercise one or more of these rights (including the right to withdraw your consent when we process
                  your data on the basis of your consent) or to ask any questions about any of these rights or any
                  provision of this notice or any other aspect of our processing of your data, please feel free to use
                  the contact details in section 2 above (Our contact details) at any time.
                  <br />
                  <br />
                  We will try to respond as quickly and thoroughly as possible to all your questions and concerns and
                  facilitate the exercise of your rights.
                  <h3 className={'font-big font-weight-light-bold paragrah'}>
                    11. When does this Notice become applicable
                  </h3>
                  This Information Notice applies to all actions and data involved in the use of the Docviser
                  Application, solely for the purpose stated.
                  <h3 className={'font-big font-weight-light-bold paragrah'}>12. Amendments to this Notice</h3>
                  We may amend this Notice from time to time. In such cases, by any changes we may make to this notice
                  we will not reduce the rights you have with respect to your data.
                  <h3 className={'font-big font-weight-light-bold paragrah'}>13. Meaning of certain terms</h3>
                  The supervisory authority for the processing of personal data: an independent public authority which,
                  under the law, has powers relating to the supervision of compliance with personal data protection
                  legislation. In Romania, this supervisory authority for the processing of personal data is the
                  National Supervisory Authority for Personal Data Processing (NSAPDP).
                  <br />
                  <br />
                  Special categories of personal data (sensitive personal data/sensitive data): personal data revealing
                  racial or ethnic origin, political opinions, religious or philosophical beliefs, or trade union
                  membership, genetic data, biometric data for the purpose of uniquely identifying a natural person,
                  data concerning health or data concerning a natural person's sex life or sexual orientation.
                  <br />
                  <br />
                  Personal data: any information relating to an identiﬁed or identiﬁable natural person (referred to as
                  a 'data subject'). An identifiable natural person is one who can be identified, directly or
                  indirectly, in particular by reference to an identifier such as a name, an identification number,
                  location data, an online identifier or to one or more factors specific to the physical, physiological,
                  genetic, mental, economic, cultural or social identity of that natural person. Thus, for example, the
                  following are included in the notion of personal data: name and surname; home or residence address;
                  e-mail address; telephone number; personal identification number (CNP); diagnosis established (this is
                  sensitive data); genetic data (this is sensitive data); biometric data (this is sensitive data);
                  geolocation data. The categories of personal data about you that we process are listed above.
                  <br />
                  <br />
                  Controller: the natural or legal person who determines the purposes and means of the processing of
                  personal data. According to the law, the primary responsibility for compliance with personal data
                  legislation lies with the controller. In their relationship with you, the Application Users, as well
                  as Docviser, are controllers.
                  <br />
                  <br />
                  Processor: a natural or legal person which processes personal data on behalf of the controller, other
                  than the employees of the controller.
                  <br />
                  <br />
                  Data Subject: the natural person to whom certain personal data refer (to whom they "belong"). In
                  relation to us (the controller), you are the data subject.
                  <br />
                  <br />
                  Processing of personal data: any operation or set of operations which is performed on personal data or
                  on sets of personal data, whether or not by automated means, such as collection, recording,
                  organisation, structuring, storage, adaptation or alteration, retrieval, consultation, use, disclosure
                  by transmission, dissemination or otherwise making available, alignment or combination, restriction,
                  erasure or destruction; These are only examples. Basically, processing means any operation on personal
                  data, whether by automatic or manual means.
                  <br />
                  <br />
                  User: any person who accesses the Application, creates an account on the Application or wishes to use
                  in any way or actually uses the Service, i.e. Clinic/ Hospital/Super Doctor/Doctor/Pharmacist/Nurse
                  etc.
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

PrivacyPolicy.propTypes = {
  location: PropTypes.shape({}).isRequired,
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { ns: { in: ["privacy-policy"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default withTranslation()(PrivacyPolicy);
