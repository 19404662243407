import React, { useState } from 'react';
import MuiAccordion from '@mui/material/Accordion';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { Colors } from '../constants/Colors';

const AccordionComponent = ({ items, plusMinusIcon }) => {
  const [expanded, setExpanded] = useState('');

  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : '');
  };

  return (
    <>
      {items.map((item, index) => (
        <MuiAccordion
          key={`accordion-${index}`}
          expanded={expanded === `panel${index}`}
          onChange={handleChange(`panel${index}`)}
          disableGutters
          elevation={0}
          variant={'outlined'}
          sx={{
            backgroundColor: 'transparent',
            padding: '5px 8px',
            border: `1px solid ${Colors.Border}`,
            borderRadius: '0.8rem !important',
            '&::before': { display: 'none' },
          }}
        >
          <MuiAccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ fill: Colors.DarkGrey }} style={{ width: '3.2rem', height: '3.2rem' }} />}
            sx={{
              flexDirection: 'row',
              '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                transform: plusMinusIcon ? 'rotate(0deg)' : 'rotate(180deg) ',
              },
            }}
          >
            <div className={'testimonials-text'}>
              <span dangerouslySetInnerHTML={{ __html: item.question }} />
            </div>
          </MuiAccordionSummary>
          <MuiAccordionDetails
            sx={{
              paddingLeft: '16px',
              paddingRight: '16px',
              paddingTop: '8px',
            }}
          >
            <div className={'testimonials-text'} dangerouslySetInnerHTML={{ __html: item.answer }}></div>
          </MuiAccordionDetails>
        </MuiAccordion>
      ))}
    </>
  );
};

export default AccordionComponent;
