import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const TitleLine = props => {
  const { title, classNames } = props;

  return <div className={classnames('title-line', classNames)}>{title}</div>;
};

TitleLine.propTypes = {
  title: PropTypes.string,
};

export default TitleLine;
