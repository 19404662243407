import React from 'react';
import PropTypes from 'prop-types';
import Loadable from 'react-loadable';

import Header from './Header';
import '../assets/scss/main.scss';

require('prismjs/themes/prism-okaidia.css');

const Loading = () => <h3>Loading...</h3>;

const FooterComponent = Loadable({
  loader: () => import('./Footer'),
  loading: Loading,
});

const WebinarFooterComponent = Loadable({
  loader: () => import('./Webinar/WebinarFooter'),
  loading: Loading,
});

class Template extends React.Component {
  constructor(props) {
    const url = typeof window !== 'undefined' ? window.location.href : '';
    const isWebinar = url.includes('webinar');

    super(props);
    this.state = {
      isMenuVisible: false,
      isWebinar,
    };
  }

  componentDidMount() {
    return setTimeout(() => {
      const scriptHubspot = document.createElement('script');
      scriptHubspot.type = 'text/javascript';
      scriptHubspot.id = 'hs-script-loader';
      scriptHubspot.async = true;
      scriptHubspot.defer = true;
      scriptHubspot.src = '//js.hs-scripts.com/6671562.js';
      const x = document.getElementsByTagName('script')[0];
      x.parentNode.insertBefore(scriptHubspot, x);
    }, 3000);
  }

  handleToggleMenu = () => {
    const { isMenuVisible } = this.state;

    this.setState({
      isMenuVisible: !isMenuVisible,
    });
  };

  render() {
    const { children } = this.props;
    const { isMenuVisible } = this.state;

    return (
      <>
        <div className={`body ${isMenuVisible ? 'is-menu-visible' : ''}`}>
          <div id="wrapper">
            <Header onToggleMenu={this.handleToggleMenu} />
            {children}
          </div>
          <div id="wrapper-footer">
            <div className="footer">{this.state.isWebinar ? <WebinarFooterComponent /> : <FooterComponent />}</div>
          </div>
        </div>
      </>
    );
  }
}

Template.propTypes = {
  children: PropTypes.node,
  location: PropTypes.shape({}).isRequired,
};

Template.defaultProps = {
  children: null,
};

export default Template;
