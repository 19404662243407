import React from 'react';

import Button from '../Button';
import TitleLine from '../Subsection/TitleLine';

export const ChooseDocviserSection = ({ t }) => {
  return (
    <div className="choose-docviser">
      <div className={'title-container'}>
        <TitleLine title={t('home.mainSection.chooseDocviser')} classNames={''} />
        <TitleLine title={t('home.mainSection.joinFree') + '!'} classNames={'text-gradient'} />
        <div className={'description'}>{t('home.mainSection.nextLevel') + '!'}</div>
      </div>
      <Button
        goTo="https://calendly.com/alex-docviser-1/30min"
        title={t('navigation.scheduleADemo')}
        type="primary-dark-button"
      />
    </div>
  );
};
