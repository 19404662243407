import React from 'react';
import { Link } from 'gatsby';

import Twitter from '../../assets/images/Twitter.svg';
import Facebook from '../../assets/images/Facebook.svg';
import Linkedin from '../../assets/images/Linkedin.svg';
import LogoWhite from '../../assets/images/logo-white.svg';
import Button from "../Button";

const Footer = () => {
  return (
    <footer id="footer" className="main__inner">
      <div className="footer">
        <div className="footer__item footer__logo-section">
          <div className="footer__item-logo">
            <Link to="/" target="_self">
              <img src={LogoWhite} alt="DOCVISER" title="DOCVISER" width="110" height="29" />
            </Link>
          </div>
        </div>
        <div className="footer__main-section">
          <div className="footer__item">
            <div className="footer__item-columns">
              <p className="footer__item-subtitle font-weight-light-bold">Website</p>
              <div className="footer__item-columns-margin">
                <p>
                  <a
                    href="https://docviser.io"
                    rel="noopener noreferrer"
                    className="footer__item-link footer__item-columns-small-margin"
                  >
                    docviser.io
                  </a>
                </p>
              </div>
              <br />
            </div>
            <div className="footer__item-columns">
              <Button goTo="/" type="primary-dark" title="Viziteaza website-ul" />
            </div>
          </div>
          <div className="footer__item" tabIndex="0">
            <div className="footer__item-columns">
              <p className="footer__item-subtitle font-weight-light-bold">Cluj Office</p>
              <div className="footer__item-columns-margin">
                <p>Marin Preda 10-12,</p>
                <p>Cluj-Napoca, Romania, 400502</p>
              </div>
              <br />
            </div>
            <div className="footer__item-columns">
              <p className="footer__item-subtitle font-weight-light-bold">Contact</p>
              <div className="footer__item-columns-margin">
                <p>
                  <a href="tel:+40747215726" rel="noopener noreferrer" className="footer__item-link">
                    +4 074 721 5726
                  </a>
                </p>
                <p>
                  <a href="mailto:contact@docviser.io" rel="noopener noreferrer" className="footer__item-link">
                    contact@docviser.io
                  </a>
                </p>
              </div>
            </div>
            
          </div>
        </div>
        <div className="footer__item footer__follow-section">
          <h4 className="footer__item-title font-weight-light-bold" tabIndex="0">
            Follow us
          </h4>
          <div className="footer__item">
            <ul>
              <li>
                <a href="https://twitter.com/DocviserIO" rel="noopener noreferrer" className="footer__item-link">
                  <img src={Twitter} alt="Twitter" title="Twitter" width="24" height="24" />
                </a>
              </li>
              <li>
                <a href="https://www.facebook.com/docviser" rel="noopener noreferrer" className="footer__item-link">
                  <img src={Facebook} alt="Facebook" title="Facebook" width="24" height="24" />
                </a>
              </li>
              <li>
                <a
                  href="src/components/Webinar/WebinarFooter"
                  rel="noopener noreferrer"
                  className="footer__item-link"
                >
                  <img src={Linkedin} alt="Linkedin" title="Linkedin" width="24" height="24" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
