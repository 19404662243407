const data = [
  {
    image: require('../assets/images/aboutUs/alexandru_lazar.jpg'),
    name: 'Alexandru Lazar',
    top: true,
    position: 'Chief Executive',
    description: `Tech Leader & Entrepreneur, Alex is the head of sales, operations, partnerships and global
      technical directions within the company.`,
  },
  {
    image: require('../assets/images/aboutUs/alexandru_iliescu.jpg'),
    name: 'Alexandru Iliescu',
    top: true,
    position: 'Chief Scientist ',
    description: `Chief Scientist Officer. The oncology expert behind our platform.`,
  },
  {
    image: require('../assets/images/aboutUs/adrian_barta.jpg'),
    name: 'Adrian B.',
    position: 'Architect',
    description: '',
  },
  {
    image: require('../assets/images/aboutUs/horia_iacos.jpg'),
    name: 'Horia I.',
    hidden: true,
    position: 'Architect',
    description: ``,
  },
  {
    image: require('../assets/images/aboutUs/vlad_zahan.jpg'),
    name: 'Vlad Z.',
    hidden: true,
    position: 'Project Manager',
    description: '',
  },
  {
    image: require('../assets/images/aboutUs/ana_maria_demian.jpg'),
    name: 'Ana-Maria D.',
    position: 'Technical Lead',
    description: '',
  },
  {
    image: require('../assets/images/aboutUs/mihnea_miron.jpg'),
    name: 'Mihnea M.',
    position: 'Technical Lead',
    description: '',
  },
  {
    image: require('../assets/images/aboutUs/rares_modure.jpg'),
    name: 'Rares M.',
    hidden: true,
    position: 'Software Engineer',
    description: '',
  },
  {
    image: require('../assets/images/aboutUs/cristian_bricicaru.jpg'),
    name: 'Cristian B.',
    position: 'Project Manager',
    description: '',
  },
  {
    image: require('../assets/images/aboutUs/bogdan_sandu.png'),
    name: 'Bogdan S.',
    position: 'Software Engineer',
    description: '',
  },
  {
    image: require('../assets/images/aboutUs/alexandru_sandu.png'),
    name: 'Alexandru S.',
    position: 'Software Engineer',
    description: '',
  },
  {
    image: require('../assets/images/aboutUs/madalina.png'),
    name: 'Mădălina M.',
    position: 'Software Engineer',
    description: '',
  },
  {
    image: require('../assets/images/aboutUs/anamaria_gavra.jpeg'),
    name: 'Anamaria G.',
    position: 'Software Engineer',
    description: '',
  },
  {
    image: require('../assets/images/aboutUs/cosmina_odageru.jpg'),
    name: 'Cosmina O.',
    position: 'QA',
    description: `Open minded and dedicated to the company progress Cosmina makes sure the team has everything it
      needs to be successful. She is responsible for our office administration, resourcing and HR.`,
  },
  {
    image: require('../assets/images/aboutUs/alexandra_efrimov.jpg'),
    name: 'Alexandra E.',
    hidden: true,
    position: 'Head of Product & UX',
    description: `Head of product and design`,
  },
  {
    image: require('../assets/images/aboutUs/emanuel_mateiuc.jpg'),
    name: 'Emanuel M.',
    position: 'UI/UX Designer',
    description: '',
  },
  {
    image: require('../assets/images/aboutUs/ionut_beleiu.jpg'),
    name: 'Ionut B.',
    position: 'Operations',
    description: 'An Engineer at base, Ionut is coordinating operations between sales and delivery in MCRO.',
  },
  {
    image: require('../assets/images/aboutUs/cornelius.jpg'),
    name: 'Cornelius P.',
    position: 'Business Developer',
    description: '',
  },
  {
    image: require('../assets/images/aboutUs/alexandru_sabau.jpg'),
    name: 'Alexandru S.',
    hidden: true,
    position: 'QA Automation Engineer',
    description: '',
  },
];

export default data;
