import React from 'react';
import Subtitle from '../Subsection/Subtitle';

const TermsRo = () => {
  return (
    <>
      <Subtitle title={'Termeni si Conditii'} withoutLine titleType="h1" />
      <h2 style={{ display: 'none' }}>Docviser Oncology - Termeni si Conditii</h2>
      <div className={'terms-and-privacy'}>
        Docviser („Aplicația”), dezvoltată de către DOCVISER S.R.L. (în continuare „Docviser”) va pune la dispoziția
        persoanelor şi instituţiilor implicate în sistemul medical o solutie care să simplifice şi să încurajeze
        colaborarea dintre aceştia în cadrul proceselor de tratare a unor anumite afecţiuni medicale.
        <br />
        <br />
        Accesul în Aplicaţie este oferit cu titlu gratuit clinicilor medicale şi, implicit, persoanelor care activează
        în cadrul acestor clinici, astfel încât să fie facilitată elaborarea de statistici cu implicaţie exclusiv
        medicală, de diagnostic şi de tratament a afecţiunilor vizate, în scopul eficientizării rezultatelor obţinute,
        în sensul însănătoşirii pacienţilor ori al îmbunătăţirii stării lor de sănătate, pe baza schemelor de tratament
        aplicate Pacienţilor şi inserate în Aplicaţie.
        <br />
        <br />
        Docviser permite inserarea în cadrul Aplicaţiei a unor Utilizatori (personal medical din cadrul Clinicii),
        tratamentele recomandate, precum şi deschiderea de cazuri clinice pentru studiu.
        <br />
        <br />
        Prezentul document conţine termenii şi condiţiile de utilizare a Aplicaţiei (denumit în continuare "Termeni şi
        Condiţii") şi stabileşte care sunt condiţiile în care utilizatorii pot utiliza serviciile oferite prin
        intermediul Aplicaţiei ("Utilizator").
        <br />
        <br />
        Aplicaţia este administrată de DOCVISER S.R.L., cu sediul în Comuna Feleacu, strada Dinu Lipatti, nr. 25, sat
        Feleacu, având CUI 44843120, J12/4209/02.09.2021, cod CAEN 6201
        <br />
        <br />
        Termenii şi Condiţiile din prezentul document reprezintă prevederile în baza cărora Utilizatorul va utiliza
        Aplicaţia pusă la dispoziţia sa de către Administrator.
        <br />
        <br />
        Utilizatorul înţelege şi acceptă în mod expres, în întregime şi necondiţionat, prevederile Termenilor şi
        Condiţiilor şi înţelege să utilizeze Aplicaţia în strictă conformitate cu scopul său.
        <br />
        <br />
        Utilizatorii care au un cont creat înţeleg şi acceptă că utilizarea în continuare a contului lor, precum şi
        orice accesare sau vizitare în continuare a Aplicaţiei, a oricărei pagini din acesta şi/sau utilizarea
        Serviciului, precum şi a oricărei componente a acestora constituie o acceptare în întregime şi necondiţionată a
        Termenilor şi Condiţiilor şi a oricărei prevederi din acestea; neacceptarea Termenilor şi Condiţiilor de către
        Utilizatorii menționați mai sus atrage obligaţia acestora de a înceta de îndată accesarea Aplicaţiei.
        <h3 className={'font-big font-weight-light-bold paragrah'}>1. DEFINIŢII</h3>
        1.1. „Aplicaţia" – serviciul web Docviser disponibil atât ca platformă web (varianta desktop şi varianta
        mobile), cât şi prin aplicaţia mobile pentru sistemele iOS şi Android, care pune la dispoziţia dumneavoastră, în
        calitate de Utilizator, o platformă în cadrul căreia clinica/personalul medical/farmacistul/asistentul ş.a.m.d.
        pot insera informaţii esenţiale în cadrul procesului de tratare a unor afecţiuni medicale. Utilizatorul are
        posibilitatea de a insera orice informaţii legate de diagnostic şi tratament, inclusiv cu privire la rezultatul
        obţinut, scheme de tratament, buletine de analize ş.a. Toate referirile la „Aplicaţie” din prezentul document
        includ şi Platforma on-line.
        <br />
        <br />
        1.2. „Cabinet medical individual/Clinici/Policlinici/Spitale" – orice societate parteneră a Administratorului,
        care are capacitatea de a presta/oferi servicii medicale pe teritoriul României şi care este partener al
        Administratorului, ca urmare a încheierii unui contract în vederea listării pe site şi în aplicaţie.
        <br />
        <br />
        1.3. „Contract" - Contractul încheiat între Utilizator (clinică) şi Administrator, conform Termenilor şi
        Condiţiilor, în vederea folosirii Aplicaţiei în strictă conformitate cu scopul său.
        <br />
        <br />
        1.4. „Date cu caracter personal" - orice informaţii privind o persoană fizică identificată sau identificabilă
        („persoana vizată”); o persoană fizică identificabilă este o persoană care poate fi identificată, direct sau
        indirect, în special prin referire la un element de identificare, cum ar fi un nume, un număr de identificare,
        date de localizare, un identificator online, sau la unul sau mai multe elemente specifice, proprii identităţii
        sale fizice, fiziologice, genetice, psihice, economice, culturale sau sociale; în sensul prezentului document,
        orice informaţie în legătură cu Utilizatorul, care sunt furnizate de acesta prin Platforma on-line/Aplicaţie, în
        vederea accesării, cum ar fi nume, prenume, CNP, stamp number etc., precum şi datele inserate în baza de date a
        Aplicaţiei de către Utilizator, cu privire la datele personale ale Pacienţilor.
        <br />
        <br />
        1.5. „Platforma on-line" - totalitatea funcţionalităţilor Aplicaţiei disponibile şi pentru platforma web.
        <br />
        <br />
        1.6. „Administratorul Site-ului" – DOCVISER S.R.L. cu sediul în Comuna Feleacu, strada Dinu Lipatti, nr. 25, sat
        Feleacu, înregistrată la Oficiul Registrului Comerţului de pe lângă Tribunalul Cluj sub nr. J12/4209/02.09.2021,
        cod unic de înregistrare 44843129.
        <br />
        <br />
        1.7. „Serviciu" - înseamnă orice serviciu oferit prin intermediul Aplicaţiei şi/sau orice modul sau componentă a
        acestuia, cum ar fi:
        <br />
        a. posibilitatea de insera în baza de date a Aplicaţiei statistici cu implicaţie exclusiv medicală, de
        diagnostic şi de tratament a afecţiunilor vizate de Utilizator, în scopul eficientizării rezultatelor obţinute,
        în sensul însănătoşirii pacienţilor ori al îmbunătăţirii stării lor de sănătate pe baza schemelor de tratament
        aplicate şi ulterior inserate în Aplicaţie.
        <br />
        b. posibilitatea CMI/Clinicilor/Policlinicilor/Spitalelor de a deveni partenere ale Administratorului în vederea
        listării lor în baza de date a Platformei on-line şi a Aplicaţiei.
        <br />
        <br />
        1.8. „Utilizatorul" - înseamnă în continuare orice persoană care accesează Aplicaţia/Platforma on-line, care îşi
        creează cont pe Aplicaţie/Platforma on-line sau care doreşte să utilizeze în orice mod sau utilizează efectiv
        Serviciul, respectiv Clinica/ Policlinica/ Spitalul/ Super-doctorul/ Doctorul/ Farmacistul/ Asistentul etc.
        <br />
        <br />
        <h3 className={'font-big font-weight-light-bold paragrah'}>2. DREPTURILE ASUPRA CONŢINUTULUI APLICAŢIEI</h3>
        2.1. Conţinutul şi elementele de grafică ale Aplicaţiei, inclusiv, dar fără a se limita la acestea, întreg
        conţinutul în format text şi orice alt material, transmis sub orice formă de şi către Utilizatori (prin
        vizualizare directă pe Aplicaţie/Platforma on-line, prin orice alte materiale legate direct sau indirect de
        Aplicaţie) aparţin Administratorului şi reprezintă conţinutul Aplicaţiei. Prin derogare de la prevederea
        precedentă, nu aparţin Administratorului acele materiale/informaţii pentru care pe Aplicație s-a indicat un alt
        titular de drepturi, ori un alt deţinător sau o alta sursă, cum ar fi, dar fără a se limita la, orice fel de
        date introduse în aplicaţie, după logare, de către Utilizatori.
        <br />
        <br />
        2.2. În cazurile în care conţinutul Aplicaţiei este preluat de Administrator de la alţi producători/emitenţi de
        conţinut sau materiale în format text, imagine, grafică, secvenţe video, dar fără a se limita la acestea, o
        asemenea preluare se realizează cu respectarea prevederilor legale şi regulilor incidente în asemenea cazuri.
        <br />
        <br />
        2.3. Utilizatorii înteleg şi acceptă că Administratorul este exonerat de orice răspundere privind materialele,
        datele şi informatiile, dar fără a se limita la acestea, inserate în cadrul Aplicaţiei, după logare, de către
        Utilizatori şi, totodată, se obligă să respecte toate reglementările legale în ceea ce îi priveşte. Utilizatorii
        îşi asumă că vor folosi Aplicaţia doar în scopul pentru care aceasta a fost instituită, precum şi că toate
        informaţiile, datele, materialele încărcate în Aplicaţie, după logare, vor fi în exclusiva lor responsabilitate,
        iar Administratorul nu poate fi tras la răspundere în niciun caz şi în niciun mod pentru veridicitatea şi
        validitatea Conţinutului sau pentru orice efect juridic decurgând din utilizarea acestora.
        <br />
        <br />
        2.4. Utilizatorii se obligă să respecte toate drepturile de autor şi drepturile conexe acestuia şi orice alte
        drepturi de proprietate intelectuală pe care Adminstratorul şi partenerii săi le deţin asupra/în legătură cu
        Aplicaţia, Serviciul, orice modul sau componentă a acestora sau în legătură cu utilizarea acestora, excluzând
        baza de date care este în proprietatea Utilizatorilor. Utilizatorii înteleg şi acceptă că în cazul încălcării
        vreunei prevederi de mai sus în orice măsură, vor putea fi traşi la răspundere faţă de autorii/proprietarii
        Conţinutului Aplicaţiei.
        <br />
        <br />
        2.5. Conţinutul Aplicaţiei, indiferent de zona în care se află în Aplicaţie şi indiferent de tip, poate fi
        utilizat exclusiv în scopuri medicale, în scopuri de arhivare în interes public, în scopuri de cercetare
        științifică ori în scopuri statistice, definite potrivit prezentului document. Orice utilizare de conţinut în
        orice alte scopuri decât cele prevăzute în acest document este strict interzisă.
        <br />
        <br />
        2.6. Este interzisă copierea, preluarea, reproducerea, publicarea, transmiterea, vânzarea, distribuirea totală
        sau parţială, integrală sau modificată a conţinutului acestei Aplicaţii sau a oricărei părţi a acesteia
        efectuate în orice alte scopuri decât cele prevăzute în prezentul document; Administratorul îşi rezervă dreptul
        de a acţiona în instanţă orice persoană şi/sau entitate care încalcă în orice mod prevederile de mai sus.
        <br />
        <br />
        2.7. Orice persoană care expediază în orice mod informaţii ori materiale către Aplicaţie îşi asumă obligaţia de
        a nu prejudicia în niciun mod drepturile de autor pe care o terţă persoană le-ar putea invoca în legatură cu
        materialele şi informaţiile transmise în orice mod către Aplicaţie, iar persoanele care expediază în orice mod
        informaţii ori materiale înteleg şi acceptă că încălcarea în orice mod a acestei obligaţii nu poate angaja în
        niciun mod răspunderea Administratorului, ci numai răspunderea persoanelor respective.
        <br />
        <br />
        2.8. Utilizatorii care, după logare, inserează în orice mod informaţii ori materiale în Aplicaţie, sunt de acord
        şi acceptă faptul că Administratorul nu are acces la acele materiale ori informaţii, decât în situaţii de
        excepţie legate de rezolvarea unor chestiuni de ordin tehnic, precum şi în scop de arhivare şi în scopuri
        statistice. În aceste condiţii, Administratorul nu poate fi, sub nicio formă, considerat responsabil pentru
        eventualele prejudicii cauzate de conţinutul inserat în Aplicație de către Utilizatori.
        <br />
        <br />
        2.9. Este strict interzisă încărcarea în Aplicatie a oricărui conţinut care nu are legătură cu scopul
        Aplicaţiei, incluzând materiale, comentarii, imagini, secvenţe video, dar fără a se limita la acestea, care au
        un limbaj obscen sau vulgar, conţin texte ilegale, ameninţătoare, abuzive, indecente, mesaje rasiste, șoviniste
        ori care ar putea discrimina orice persoană în orice mod sau care ar încălca orice alte drepturi şi libertăți
        ale omului potrivit legislaţiei în vigoare.
        <br />
        <br />
        2.10. Utilizatorii înţeleg şi acceptă că în cazul încălcării în orice mod şi în orice măsură a oricăror
        prevederi din prezentul act, Administratorul va putea opta în mod discreţionar pentru suspendarea totală sau
        parţială a accesului Utilizatorului la orice Serviciu al Aplicaţiei, ori pentru încărcarea oricăruri conţinut
        care nu are legătură cu scopul Aplicaţiei ori pentru modificarea în orice mod a unui asemenea conţinut
        (notificată şi probată ca atare de către un alt Utilizator), pentru interzicerea definitivă a accesului la una
        sau mai multe dintre facilităţile oferite de Aplicaţie sau pentru desfiiinţarea contului Utilizatorului, fără
        niciun fel de atenţionari sau notificări prealabile şi fără a fi necesare orice alte formalităţi în acest sens.
        <br />
        <br />
        <h3 className={'font-big font-weight-light-bold paragrah'}>3.ÎNCHEIEREA CONTRACTULUI</h3>
        3.1. Înregistrarea şi folosirea Platformei on-line/Aplicaţiei/Serviciilor constituie consimţământul expres şi
        neechivoc al Utilizatorului cu privire la încheierea Contractului care include aceşti Termeni şi Condiţii,
        precum şi Politica de confidenţialitate.
        <br />
        <br />
        3.2. Contractul dintre Administrator şi Utilizator se încheie numai dacă sunt îndeplinite toate condiţiile de
        mai jos:
        <br />
        <br />
        Utilizatorul persoană fizică are vârsta de minim 18 ani;
        <br />
        Utilizatorul accesează Platforma on-line şi/sau instalează Aplicaţia pe aparatele mobile;
        <br />
        Utilizatorul citeşte cu atenţie Termenii şi Condiţiile, precum şi Politica de confidenţialitate şi îşi exprimă
        consimţământul cu privire la acestea, bifând căsuţa „Sunt de acord cu Termenii şi condiţiile şi cu Politica de
        confidenţialitate";
        <br />
        Utilizatorul îşi creează un cont prin completarea şi transmiterea informaţiilor reale, menţionate în formularul
        de aplicare (request registration pe docviser.io) de pe Platforma on-line sau din Aplicaţie, inserând datele de
        contact solicitate de Administrator, care trebuie să corespundă cu baza de date a Administratorului pentru a-i
        fi permis accesul;
        <br />
        Utilizatorul acceptă şi înţelege că accesul nu îi va fi permis până ce identitatea sa nu va fi verificată;
        <br />
        Utilizatorul accesează pentru prima dată contul creat prin intermediul link-ului primit, ulterior momentului la
        care datele sale au fost confirmate.
        <br />
        <br />
        3.3.Contractul va fi considerat ca fiind încheiat în momentul în care Utilizatorul bifează căsuţa „Sunt de acord
        cu Termenii şi condiţiile de utilizare” la momentul creării contului.
        <br />
        <h3 className={'font-big font-weight-light-bold paragrah'}>4. PREŢUL</h3>
        4.1. Serviciile pentru listarea CMI/Clinicilor/Policlinicilor/Spitalelor care doresc să devină Utilizatori vor
        fi, de asemenea, gratuite, şi se supun prevederilor contractelor încheiate cu Administratorul.
        <br />
        <h3 className={'font-big font-weight-light-bold paragrah'}>5. PRESTAREA SERVICIULUI</h3>
        5.1. Administratorul se obliga să ofere Utilizatorului Serviciul în timp real, prin intermediul Platformei
        on-line sau al Aplicaţiei pentru aparate mobile.
        <br />
        <br />
        5.2. Utilizatorul înţelege şi este de acord cu faptul că toate drepturile de proprietate intelectuală privind
        Platforma on-line şi Aplicaţia (cu excepţia datelor inserate de către Utilizatori după logare) aparţin
        Administratorului, Utilizatorul nefiind îndreptăţit la niciun drept de proprietate intelectuală, ci doar la un
        drept de utilizare cu privire la Aplicaţie, în scopul exclusiv medical detaliat în prezentul document.
        <br />
        <br />
        5.3. Utilizatorii înţeleg si sunt de acord cu faptul că datele inserate în baza de date a Aplicaţiei sunt
        accesibile tuturor utilizatorilor care au primit acces la un caz clinic, datele urmând a fi prelucrate exclusiv
        în scopul prevăzut pentru Aplicaţie.
        <br />
        <h3 className={'font-big font-weight-light-bold paragrah'}>6. LIMITAREA RĂSPUNDERII</h3>
        6.1. Utilizatorul este pe deplin responsabil pentru statisticile cu implicaţie medicală, de diagnostic şi de
        tratament a afecţiunilor inserate în Aplicaţie.
        <br />
        <br />
        6.2. Administratorul nu garantează expres sau implicit calificările profesionale şi experienţa Utilizatorilor,
        calitatea serviciilor medicale, preţul acestora ş.a.m.d.
        <br />
        <br />
        6.3. Administratorul nu îşi asumă nicio răspundere pentru utilizarea Aplicaţiei în vederea eficientizării
        rezultatelor obţinute de către Utilizatori, în sensul însănătoşirii pacienţilor ori al îmbunătăţirii stării lor
        de sănătate. Utilizarea Serviciului în acest caz va antrena numai riscul Utilizatorului.
        <br />
        <br />
        6.4. Administratorul nu are control asupra şi nu poate garanta disponibilitatea vreunui anumit medic dintr-o
        CMI/Clinică/Policlinică/Spital într-o anumită zi sau oră.
        <br />
        <br />
        6.5. Administratorul nu poate fi ţinut responsabil pentru situaţiile în care operatorii
        CMI/Clinicilor/Policlinicilor/Spitale nu au actualizat datele inserate în Aplicaţie în timp real, în mod
        constant şi corect.
        <br />
        <br />
        6.6. Administratorul nu îşi asumă obligaţia şi nu garantează implicit sau expres pentru conţinutul de orice fel
        al Aplicaţiei sau pentru întreg conţinutul oferit de către Utilizatori. Administratorul va face toate eforturile
        rezonabile pentru a asigura acurateţea şi încrederea în Aplicaţie şi va încerca să corecteze erorile şi
        omisiunile tehnice cât mai repede posibil.
        <br />
        <br />
        6.7. Administratorul oferă garanţii cu privire la datele inserate în Aplicaţie, în sensul că aceste date sunt
        criptate la nivel de baze de date. Totuşi, Administratorul nu va fi ţinut răspunzător pentru nicio pierdere sau
        prejudiciu ce ar putea rezulta din utilizarea Aplicaţiei sau din imposibilitatea utilizării acesteia, indiferent
        de cauza acesteia ori din interpretarea eronată a oricăror prevederi ale Conţinutului Aplicaţiei.
        <br />
        <br />
        6.8. Utilizatorii înţeleg şi acceptă că furnizarea Serviciului poate fi afectată de anumite condiţii obiective,
        precum şi că Utilizatorii folosesc aceste servicii pe riscul lor. În consecinţă, Administratorul nu poate fi
        tras la răspundere nici cu privire la informaţii şi date din Conţinutul Aplicaţiei, inclusiv, dar nu numai cele
        privind textul, imaginile, secvenţele video, avatarurile sau orice altă activitate legată de utilizarea, precum
        şi niciun alt efect juridic derivând din acestea.
        <br />
        <br />
        6.9. Utilizatorii înţeleg şi acceptă că Administratorul Site-ului este absolvit de orice răspundere în
        eventualitatea producerii oricărei opriri, întreruperi, îngreunări, disfuncţionalităţi sau erori în funcţionarea
        Aplicaţiei, în situaţia unei erori tehnice de orice fel sau oricăror erori în furnizarea Serviciului, precum şi
        în orice situaţie în care nu ar dovedi cert că orice erori sau probleme de natură tehnică din cele menţionate
        mai sus se datorează în mod direct şi exclusiv culpei grave a Administratorului.
        <br />
        <br />
        6.10. În mod expres, Utilizatorii înţeleg şi acceptă că Administratorul este absolvit de orice răspundere pentru
        orice fel de daune directe, indirecte rezultate din utilizarea Serviciului sau orice alt aspect în legătură cu
        Serviciul, precum şi din utilizarea Conţinutului Aplicaţiei în orice mod sau orice efecte juridice derivând din
        aceasta.
        <br />
        <br />
        6.11. În cazurile de forţă majoră, Administratorul şi/sau operatorii, colaboratorii, angajaţii şi reprezentanţii
        săi sunt exonerati total de răspundere. Cazurile de forţă majoră includ, dar nu se limitează la acestea, erori
        de funcţionare ale echipamentului tehnic al Administratorului, lipsa funcţionării conexiunii la internet, lipsa
        funcţionării conexiunilor de telefon, viruşii informatici, atacurile informatice de orice fel şi interferarea cu
        programe informatice malițioase, accesul neautorizat în sistemele Aplicaţiei, erorile de operare, greva etc.
        <br />
        <h3 className={'font-big font-weight-light-bold paragrah'}>
          7. NOTĂ DE INFORMARE PRIVIND PROTECŢIA DATELOR PERSONALE
        </h3>
        7.1. Conform cerinţelor legislaţiei aplicabile în materia protecţiei persoanelor fizice în ceea ce priveşte
        prelucrarea datelor cu caracter personal şi libera circulaţie a acestor date, modificată şi completată, precum
        şi ale Legii nr. 506/2004 privind prelucrarea datelor cu caracter personal şi protecţia vieţii private în
        sectorul comunicaţiilor electronice, Administratorul va administra în condiţii de siguranţă şi numai pentru
        scopurile specificate, datele personale furnizate de Utilizatori, cât şi datele personale inserate în baza de
        date a Aplicaţiei.
        <br />
        Vă informăm că datele personale pe care Utilizatorii le furnizează Administratorului pentru logare sunt
        prelucrate exclusiv în scopul oferirii în condiţii optime a Serviciului, respectiv datele personale inserate
        ulterior logării de către Utilizatori în baza de date a Aplicaţiei sunt prelucrate exclusiv în scopuri medicale,
        de arhivare în interes public, de cercetare ştiinţifică ori în scopuri statistice.
        <br />
        <br />
        7.2. Pentru informaţii detaliate cu privire la prelucrarea datelor cu caracter personal vă rugam să citiţi
        Politica de prelucrare a datelor cu caracter personal, document care este parte integrantă din Termeni şi
        Condiţii.
        <h3 className={'font-big font-weight-light-bold paragrah'}>8. DURATA ŞI ÎNCETAREA CONTRACTULUI</h3>
        8.1. Contractul se consideră încheiat pentru întreaga perioadă în care Utilizatorul foloseşte Aplicaţia, aceeaşi
        fiind şi perioada în care Termenii şi condiţiile de faţă îşi găsesc aplicabilitatea.
        <br />
        <br />
        8.2. Utilizatorul poate înceta folosirea Platformei on-line şi/sau a Aplicaţiei în orice moment, cu menţiunea că
        datele inserate până la acel moment în Aplicaţie nu vor fi în mod automat şterse, fiind necesare în scopul
        prevăzut în acest document, astfel cum este descris pe larg şi în Politica de confidenţialitate.
        <br />
        <br />
        8.3. Administratorul îşi rezervă dreptul de a suspenda sau înceta imediat Serviciile către un Utilizator în
        orice moment şi fără notificare: <br /> a) în cazul în care constată sau are suspiciuni serioase cu privire la
        faptul că acesta nu respecta prevederile Termenilor şi Condiţiilor, ale Politicii de confidenţialitate sau ale
        altor politici publicate pe Platforma on-line; <br /> b) în cazul nefolosirii contului pe o perioada
        îndelungată.
        <h3 className={'font-big font-weight-light-bold paragrah'}>9. LEGEA APLICABILĂ. LITIGIILE</h3>
        9.1. Drepturile şi obligaţiile Utilizatorilor şi ale Administratorului, prevăzute de Termeni şi Condiţii, precum
        şi toate efectele juridice pe care le produc Termenii şi Condiţiile vor fi interpretate şi guvernate în
        conformitate cu legea română în vigoare.
        <br />
        <br />
        9.2. Orice litigiu născut din sau în legătură cu Termenii şi Condiţiile va fi soluţionat pe cale amiabilă. În
        caz de imposibilitate de a ajunge la un acord, litigiul va fi soluţionat de către instanţa judecătorească română
        competentă, aflată în circumscripţia de la sediul Docviser.
        <h3 className={'font-big font-weight-light-bold paragrah'}>10. MODIFICAREA TERMENILOR ŞI CONDIŢIILOR</h3>
        10.1. Administratorul are dreptul de a modifica oricând şi în orice mod oricare dintre prevederile din Termeni
        şi Condiţii, fără notificare prealabilă şi fără a fi obligat să îndeplinească vreo alta formalitate faţă de
        Utilizatori.
        <br />
        <br />
        10.2. Orice modificare va fi considerată ca fiind acceptată pe deplin şi necondiţionat de către oricare dintre
        Utilizatori prin simpla utilizare sau accesare a oricărei facilităţi oferite de Aplicaţie sau de Serviciu ori
        prin accesarea Aplicaţiei, intervenite oricând după operarea modificării, iar neacceptarea oricărei modificări
        atrage obligaţia respectivului Utilizator de a înceta de îndată accesarea Aplicaţiei şi/sau utilizarea în orice
        mod a Serviciului.
        <br />
        <br />
        10.3. Utilizatorul este sfătuit să verifice periodic Termenii şi Condiţiile.
        <br />
        <br />
      </div>
    </>
  );
};

export default TermsRo;
