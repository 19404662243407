import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import { useMediaQuery } from 'react-responsive';

const Article = ({ title, readingTime, date, path, category, imageFluid, imageFluidMobile }) => {
  const isMobile = useMediaQuery({
    query: '(max-device-width: 414px)',
  });

  const articleImage = isMobile && imageFluidMobile ? imageFluidMobile : imageFluid;

  return (
    <div className="article">
      <div className="article__image">
        <Link to={path} tabIndex="-1">
          {imageFluid && articleImage ? (
            <Img className="article__image" fluid={articleImage} alt={title} title={title} width="375" height="250" />
          ) : (
            <img className="article__image" src={articleImage} alt={title} title={title} width="375" height="250" />
          )}
        </Link>
      </div>
      <div className="article__category">{category}</div>
      <h2 className="article__title">
        <Link to={path}>{title}</Link>
      </h2>
    </div>
  );
};

export default Article;
