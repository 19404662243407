export const arrayIncludesAnotherArray = (needle, haystack) => {
  for (let i = 0; i < needle.length; i++) {
    if (haystack.indexOf(needle[i]) === -1) {
      return false;
    }
  }

  return true;
};

// eslint-disable-next-line
export const arrayIncludesSubstring = (substring, array) => {
  return array && array.length ? array.indexOf(substring) !== -1 : false;
};

export const xor = (array, element) => {
  const indexOfTag = array.indexOf(element);

  if (indexOfTag === -1) {
    array.push(element);
  } else {
    array.splice(indexOfTag, 1);
  }

  return array;
};

  // eslint-disable-next-line
export const arraysIntersect = (needle, haystack) =>
  // eslint-disable-next-line
  needle && haystack ? needle.filter(element => haystack.includes(element)).length : false;
