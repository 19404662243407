export const languages = ['en', 'ro', 'de', 'fr', 'nl', 'hu']; /// add here all supported languages

export const resetLanguage = () => {
  const isBrowser = typeof window !== 'undefined';
  if (isBrowser) {
    const path = window.location.pathname;
    const langDetected = path && path.split('/')[1];
    const LSLanguage = localStorage.getItem('gatsby-i18next-language');
    if (langDetected && languages.indexOf(langDetected) >= 0 && LSLanguage !== langDetected) {
      localStorage.setItem('gatsby-i18next-language', langDetected);
    }
  } else {
    console.log('isBrowser ------------------->> ', isBrowser);
  }
};
