import React, { Component } from 'react';
import HubspotForm from 'react-hubspot-form';
import hubSpotConfig from '../config/hubspotConfig';

const { portalId, formId, webinarFormId } = hubSpotConfig;

class NewQuoteForm extends Component {
  componentDidMount() {
    if (typeof window !== 'undefined') {
      window.addEventListener('message', event => {
        if (event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormSubmit') {
          this.setCookie('hubspotutk', '', -1);
        }
      });
    }
  }

  setCookie = (name, value, days) => {
    const d = new Date();
    d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * days);
    document.cookie = `${name} = ${value} ;path=/;expires= ${d.toGMTString()}`;
  };

  render() {
    return (
      <>
        <div id="hubspot-form-container"></div>
        <div id="quote-form">
          <HubspotForm
            portalId={portalId}
            formId={this.props.isWebinar ? webinarFormId : formId}
            onFormSubmit={() => console.log('submit')}
            onSubmit={() => console.log('submitted!')}
            target="#hubspot-form-container"
            className="robots-nocontent"
          />
        </div>
      </>
    );
  }
}

export default NewQuoteForm;
