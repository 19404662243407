import * as React from 'react';
import { graphql, Link } from 'gatsby';
import { withTranslation } from 'gatsby-plugin-react-i18next';

// styles
const pageStyles = {
  color: '#232129',
  padding: '96px',
  fontFamily: '-apple-system, Roboto, sans-serif, serif',
};
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  maxWidth: 320,
};

const paragraphStyles = {
  marginBottom: 48,
};
const codeStyles = {
  color: '#8A6534',
  padding: 4,
  backgroundColor: '#FFF4DB',
  fontSize: '1.25rem',
  borderRadius: 4,
};

// markup
const NotFoundPage = ({ t }) => {
  return (
    <main style={pageStyles}>
      <title>{t('notFound.title')}</title>
      <h1 style={headingStyles}>{t('notFound.header')}</h1>
      <p style={paragraphStyles}>
        {t('notFound.sorry')}{' '}
        <span role="img" aria-label="Pensive emoji">
          😔
        </span>{' '}
        {t('notFound.couldntFind')}
        <br />
        {process.env.NODE_ENV === 'development' ? (
          <>
            <br />
            {t('notfound.createPage')} <code style={codeStyles}>src/pages/</code>.
            <br />
          </>
        ) : null}
        <br />
        <Link to="/">{t('notFound.createPage')}</Link>.
      </p>
    </main>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { ns: { in: ["index"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default withTranslation()(NotFoundPage);
