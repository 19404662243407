import React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import { graphql, navigate } from 'gatsby';
import { withTranslation } from 'gatsby-plugin-react-i18next';

import Layout from '../components/GatsbyLayout';
import { links, validIds } from '../data/Features';
import { ChooseDocviserSection } from '../components/Sections/ChooseDocviserSection';

const PatientManagement = ({ id, t, location }) => {
  if (!validIds.includes(id) && typeof window !== 'undefined') {
    navigate('/404');
    return null;
  }

  const images = links[id];
  const imgs = images?.keys().map(image => images(image));

  return (
    <>
      <Layout location={location}>
        <Helmet
          title={t(`${id}.seoTitle`)}
          description={t(`${id}.seoDescription`)}
          keywords={t(`${id}.seoKeywords`)}
          canonical
        />
        <div className="home">
          <div className="main main__inner">
            <div className="padding-top-30 title-container title-container-gap100">
              <div className="title-container">
                <h2 className="home-title">
                  {t(`${id}.title`)}
                  <span className={'home-title text-gradient'}>{t(`${id}.title2`)}.</span>
                </h2>
                <div className="description">{t(`${id}.description`)}</div>
              </div>
              {Array.isArray(imgs) &&
                imgs.map((item, index) => {
                  const hasImageMargin = id === 'patientManagement' && index === 0;
                  return (
                    <div className="flex-container" key={'management-' + index}>
                      <div
                        style={{ width: !hasImageMargin ? '55%' : '65%' }}
                        className={'fullWidth ' + (!hasImageMargin && 'margin27')}
                      >
                        <img className={!hasImageMargin && 'scale'} src={item.default} alt={''} width={'100%'} />
                      </div>
                      <div
                        className={
                          'title-container title-container-left width32 testimonials-text' +
                          (index % 2 === 1 && ' order-1')
                        }
                      >
                        <h4 className={'testimonials-text weight600'}>
                          <i>{t(`${id}.titles.` + (index + 1))}</i>
                        </h4>
                        <h4 className={'testimonials-text'}>{t(`${id}.descriptions.` + (index + 1))}</h4>
                      </div>
                    </div>
                  );
                })}
              <ChooseDocviserSection t={t} />
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

PatientManagement.propTypes = {
  location: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  id: PropTypes.string,
};

PatientManagement.defaultProps = {
  data: null,
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { ns: { in: ["index"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default withTranslation()(PatientManagement);
