import React, { useState } from 'react';
import { Tab, Tabs } from '@mui/material';
import { useI18next } from 'gatsby-plugin-react-i18next';

import Statistics from '../../assets/images/homePage/statistics.png';
import Statistics_RO from '../../assets/images/homePage/statistics_RO.png';
import Checkmark from '../../assets/images/homePage/checkmark.png';

const tabsContent = ['safety', 'efficiency', 'ai'];

export const AboutSection = ({ t }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const { language } = useI18next();
  const imgSrc = language === 'ro' ? Statistics_RO : Statistics;
  const content = (info, index) => t(`home.aboutSection.${info}.${tabsContent[tabIndex]}${index ? `.${index}` : ''}`);

  return (
    <div className="title-container title-container-big-gap">
      <div className="title-container">
        <h2 className="home-title">
          {t('home.aboutSection.title1')}
          <span className="text-gradient">.</span>
        </h2>
        <div className="description">{t('home.aboutSection.description')}</div>
      </div>
      <div className="flex-container">
        <img src={imgSrc} alt="icon" width={'438'} />
        <div className={'tabs-menu'}>
          <Tabs value={tabIndex} onChange={(event, newValue) => setTabIndex(newValue)}>
            <Tab value={0} label={t('home.aboutSection.safety')} className="description" />
            <Tab value={1} label={t('home.aboutSection.efficiency')} className="description" />
            <Tab value={2} label={t('home.aboutSection.ai')} className="description" />
          </Tabs>
          <div className={'content'}>
            <div className={'title'}>
              {content('title')} <span className={'title title-text-gradient'}>{content('subtitle')}</span>
              {content('title2')}
            </div>
            <ul className={'description description-dark list'}>
              {[1, 2, 3, 4].map(index => (
                <li key={`about-${index}`}>
                  <img src={Checkmark} alt="check" />
                  <div>{content('content', index)}</div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
