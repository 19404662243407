import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SearchIcon from '../assets/images/blog/search.svg';

class Search extends Component {
  handleOnIconClick = () => {
    const { value, onClearIconClick } = this.props;

    if (value) {
      onClearIconClick();
    }
  };

  render() {
    const { onChange, placeholder, value } = this.props;

    return (
      <div className="search">
        <label htmlFor="search" className="label">search</label>
        <img className="search__icon" src={SearchIcon} alt="icon"/>
        <input
          className="search__input"
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          id="search"
        />
      </div>
    );
  }
}

Search.propTypes = {
  onChange: PropTypes.func.isRequired,
  onClearIconClick: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string,
};

Search.defaultProps = {
  placeholder: 'Search',
  value: '',
};

export default Search;
