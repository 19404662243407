import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { withTranslation } from 'gatsby-plugin-react-i18next';

import Helmet from '../components/Helmet';
import Layout from '../components/GatsbyLayout';
import ContactForm from '../components/ContactForm';

const renderHelmet = t => (
  <Helmet title={t('contact.seoTitle')} description={t('contact.seoDescription')} canonical canonicalPage="contact" />
);

const Contact = ({ location, t }) => (
  <Layout location={location}>
    <div className="home">
      {renderHelmet(t)}
      <h1 className="show-for-seo">{t('contact.showForSeo')}</h1>
      <div id="contact" className="main main__inner">
        <ContactForm t={t} />
      </div>
    </div>
  </Layout>
);

Contact.propTypes = {
  location: PropTypes.shape({}).isRequired,
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { ns: { in: ["index"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default withTranslation()(Contact);
