import React from 'react';
import Subtitle from '../Subsection/Subtitle';

const TermsEn = () => {
  return (
    <>
      <Subtitle title={'Terms & Conditions'} withoutLine titleType="h1" />
      <h2 style={{ display: 'none' }}>Docviser Oncology - Terms & Conditions</h2>
      <div className={'terms-and-privacy'}>
        Docviser ("Application"), developed by DOCVISER SA (hereinafter "Docviser") will provide people and institutions
        involved in the medical system with a solution that simplifies and encourages collaboration between them in the
        treatment of certain diseases medical.
        <br />
        <br />
        Access to the Application is provided free of charge to medical clinics and, implicitly, to persons working in
        these clinics, so as to facilitate the elaboration of statistics with exclusively medical implication, diagnosis
        and treatment of the targeted diseases, in order to streamline the results obtained, in the sense of healing
        patients or improving their health, based on the treatment schemes applied to Patients and inserted in the
        Application.
        <br />
        <br />
        Docviser allows the insertion of Users and recommended treatments into the Application and the opening of
        clinical cases for study. <br />
        <br />
        This document contains the terms and conditions of use for the Application (hereinafter "Terms and Conditions")
        and sets out the conditions under which Users may use the services offered through the Application (hereinafter
        "User").
        <br />
        <br />
        The Application is managed by DOCVISER S.A., with its registered office in Feleacu, Dinu Lipatti Street, no. 25,
        Feleacu village, with CUI 44843120, J12/4209/02.09.2021, CAEN code 6201.
        <br />
        <br />
        The Terms and Conditions in this document represent the provisions under which the User will use the Application
        made available by the Administrator.
        <br />
        <br />
        The user expressly understands and accepts, in full and unconditionally, the provisions of the Terms and
        Conditions and understands to use the Application in strict accordance with its purpose.
        <br />
        <br />
        Users who have an account created understand and agree that through using their account, as well as through
        accessing or visiting the Application or any of its pages and/or using the Service, and any component thereof,
        constitutes full and unconditional acceptance of the Terms and Conditions and any of its provisions; failure to
        accept the Terms and Conditions by the aforementioned Users shall entail their obligation to immediately cease
        accessing the Application.
        <h3 className={'font-big font-weight-light-bold paragrah'}>1. DEFINITIONS</h3>
        1.1. "Application" - the Docviser web service available both as a web platform (desktop version and mobile
        version) and as a mobile application for iOS and Android systems, which provides the User with a platform where
        the clinic/doctor/pharmacist/assistant etc. can insert essential information in the process of treating medical
        conditions. The User has the possibility to insert any information related to diagnosis and treatment, including
        outcome information, treatment schemes, medical test reports, etc. All references to "Application" in this
        document include the Online Platform.
        <br />
        <br />
        1.2. "Individual Medical Practice/Clinics/Hospitals" - any partner entity of the Administrator which has the
        capacity to provide medical services on the territory of Romania and which became a partner of the Administrator
        as a result of the conclusion of a contract between the two which offers the entity the right to be listed in
        the Application.
        <br />
        <br />
        1.3. "Contract" - the contract concluded between the User (clinic) and the Administrator in accordance with the
        Terms and Conditions for the strict use of the Application in accordance with its purpose. <br />
        <br />
        1.4. "Personal Data" - any information relating to an identified or identifiable natural person (‘data
        subject’); an identifiable natural person is one who can be identified, directly or indirectly, in particular by
        reference to an identifier such as a name, an identification number, location data, an online identifier or to
        one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social
        identity of that natural person; for the purposes of this document, any information relating to the User that is
        provided by the User for the access in the Application, such as name, surname, CNP, stamp number, etc. as well
        as the data entered into the Application's database by the User with regard to the Patient's personal data.
        <br />
        <br />
        1.5. "Administrator" - DOCVISER S.A. with its registered office in Feleacu, Dinu Lipatti Street, no. 25, Feleacu
        village, registered at the Trade Register Office of the Cluj Court under no. J12/4209/02.09.2021, unique
        registration code 44843129.
        <br />
        <br />
        1.6. "Service" - means any service provided through the Application and / or any module or component thereof,
        such as: a. the possibility for Individual Medical Practice/Clinics/Hospitals to become partners of the
        Administrator in order to be listed in the database of the Application. b. the possibility to generate
        statistics with medical implications exclusively concerning the diagnosis and treatment of the conditions
        targeted by the User, in order to make the results obtained more efficient, in the sense of improving patients'
        health on the basis of the treatment schemes applied.
        <br />
        <br />
        1.7. "User" - further means any person who accesses the Application / Online Platform, who creates an account on
        the Application / Online Platform or who wishes to use in any way or actually use the Service, respectively the
        Clinic / Polyclinic / Hospital / Super -doctor / Doctor / Pharmacist / Assistant etc.
        <br />
        <br />
        <h3 className={'font-big font-weight-light-bold paragrah'}>2. RIGHTS OVER THE CONTENT OF THE APPLICATION</h3>
        2.1. The content and graphics of the Application, including but not limited to all text content and any other
        material, transmitted in any form by and to Users (by direct viewing on the Application, by any other material
        directly or indirectly related to the Application) belong to the Administrator and represent the content of the
        Application. Notwithstanding the foregoing provision, those materials/information for which another right
        holder, owner or source has been indicated on the Application, such as, but not limited to, any data entered
        into the Application by Users after logging in, do not belong to the Administrator.
        <br />
        <br />
        2.2. In cases where the content of the Application, material in text, image, graphic, video sequences etc. is
        taken over by the Administrator from other content producers or issuers, such taking over shall be carried out
        in compliance with the legal provisions and rules applicable in such cases.
        <br />
        <br />
        2.3. The Users understand and accept that the Administrator is exempt from any liability for the materials, data
        and information, but not limited to these, inserted into the Application after logging in by the Users and at
        the same time they undertake to comply with all legal regulations concerning them. Users accept that they will
        use the Application only for the purpose for which it was established, and that all information, data and
        materials uploaded to the Application after logging in will be their sole responsibility, and that the
        Administrator cannot be held liable in any case and in any way for the truthfulness and validity of the Content
        or for any legal effect arising from their use.
        <br />
        <br />
        2.4. Users agree to respect all copyrights and related rights and any other intellectual property rights that
        the Administrator and its partners hold with regard to the Application, the Service, any module or component
        thereof or in connection with their use. Users understand and agree that in the event of a breach of any of the
        above to any extent, they may be held liable by the authors/owners of the Application Content.
        <br />
        <br />
        2.5. The Content of the Application, regardless of the area in which it is located in the Application and
        regardless of its type, may be used exclusively for medical purposes, for archiving purposes in the public
        interest, for scientific research purposes or for statistical purposes, as defined herein. Any use of the
        Content for any purpose other than as provided herein is strictly prohibited.
        <br />
        <br />
        2.6. It is prohibited to copy, take over, reproduce, publish, transmit, sell, distribute in whole or in part,
        the contents of this Application or any part thereof for any purposes other than those provided for herein; the
        Administrator reserves the right to take legal action against any person and/or entity that violates the above
        provisions in any way.
        <br />
        <br />
        2.7. Any person sending information or materials to the Application in any manner whatsoever undertakes not to
        infringe in any way any copyright that a third party may claim in connection with the materials and information
        sent in any manner whatsoever to the Application. Those sending information or materials in any manner
        whatsoever understand and agree that any breach of this obligation shall not give rise to any liability on the
        part of the Administrator, but only on the part of such persons.
        <br />
        <br />
        2.8. Users who, after logging in, enter information or materials into the Application in any way, agree and
        accept that the Administrator does not have access to those materials or information, except in exceptional
        circumstances related to the resolution of technical issues, as well as for archiving and statistical purposes.
        Under these conditions, the Administrator cannot be held responsible in any way for any damage caused by the
        content inserted in the Application by the Users.
        <br />
        <br />
        2.9. It is strictly forbidden to upload to the Application any content that is not related to the purpose of the
        Application, including, but not limited to, material, comments, images, video sequences that contain obscene or
        vulgar language, illegal, threatening, abusive, indecent texts, racist, chauvinistic messages or that could
        discriminate against any person in any way or that would violate any other human rights and freedoms according
        to the legislation in force.
        <br />
        <br />
        2.10. Users understand and agree that in the event of a breach in any manner and to any extent of any of the
        provisions hereof, the Administrator shall have the discretionary power to suspend in whole or in part the
        User's access to any of the Services of the Application. For uploading any content not related to the purpose of
        the Application or for modifying in any way such content (notified and proven as such by another User), the
        Administrator shall have the discretionary power to permanently prohibit access to one or more of the facilities
        offered by the Application or to terminate the User's account, without any prior warning or notification and
        without the need for any other formalities in this regard.
        <br />
        <br />
        <h3 className={'font-big font-weight-light-bold paragrah'}>3. CONCLUSION OF THE CONTRACT</h3>
        3.1. The registration and use of the Application constitutes the User's express and unequivocal consent to the
        conclusion of the Contract which includes these Terms and Conditions and the Data Protection Policy.
        <br />
        <br />
        3.2. The Agreement between the Administrator and the User regarding the use of the Application is concluded only
        if all the conditions below are met:
        <br />
        <br />
        The User accesses the Application; <br />
        The User carefully reads the Terms and Conditions and the Data Protection Policy and agrees to them by ticking
        the box "I agree to the Terms and Conditions and the Data Protection Policy";
        <br />
        On the basis of the contact form, the User will enter his/her data so that a representative of the Administrator
        can then contact him/her;
        <br />
        The User accepts and understands that access will not be granted until his/her identity has been verified;
        <br />
        The User first accesses the account created via the link received after his/her data has been confirmed.
        <br />
        <br />
        3.3. The contract will be considered concluded when the User ticks the box "I agree to the Terms and Conditions
        of Use" when creating the account.
        <h3 className={'font-big font-weight-light-bold paragrah'}>4. PRICING</h3>
        4.1. The services for the listing of Individual Medical Practice/Clinics/Hospitals wishing to become Users will
        be subject to a maintenance fee that is going to be agreed between the Administrator and the User.
        <h3 className={'font-big font-weight-light-bold paragrah'}>5. PROVISION OF THE SERVICE</h3>
        5.1. The Administrator shall be obliged to provide the User with the Service in real time via the Application.
        <br />
        <br />
        5.2. The User understands and agrees that all intellectual property rights related to the Application (except
        for the data entered by the Users after logging in) belong to the Administrator, and the User is not entitled to
        any intellectual property rights, but only to a right of use with respect to the Application for the exclusive
        medical purposes detailed herein. <br />
        <br />
        5.3. Users understand and agree that the data entered in the database of the Application is accessible to all
        users who have been granted access to a clinical case, and that the data is processed exclusively for the
        purposes envisaged for the Application.
        <h3 className={'font-big font-weight-light-bold paragrah'}>6. LIMITATION OF LIABILITY</h3>
        6.1. The User is fully responsible for the observations regarding the medical implications, the diagnose and the
        treatment of the medical conditions entered in the Application.
        <br />
        <br />
        6.2. The Administrator does not expressly or implicitly guarantee the professional qualifications and experience
        of the medical staff working in the Individual Medical Practice/Clinic/Hospital, the quality of medical
        services, their price, etc. <br />
        <br />
        6.3. The Administrator does not assume any liability in the sense of improving pacients' health. The use of the
        Service in this case shall be at the sole risk of the User.
        <br />
        <br />
        6.4. The Administrator has no control over and cannot guarantee the availability of any particular doctor in an
        Individual Medical Practice/Clinic/Hospital on any particular day or time. <br />
        <br />
        6.5. The Administrator cannot be held responsible for situations in which the operators of the Individual
        Medical Practice/Clinics/Hospitals have not updated the data entered in the Application in real time,
        consistently and correctly.
        <br />
        <br />
        6.6. The Administrator does not assume any obligation and does not implicitly or expressly guarantee for the
        content of any kind of the Application or for all content provided by Users. The Administrator shall use all
        reasonable efforts to ensure the accuracy and reliability of the Application and shall attempt to correct
        technical errors and omissions as quickly as possible.
        <br />
        <br />
        6.7. The Administrator guarantees that the data inserted in the Application is encrypted. However, the
        Administrator shall not be held liable for any loss or damage that may result from the use of the Application or
        the inability to use the Application, regardless of the cause thereof or from the misinterpretation of any
        provisions of the Application Content.
        <br />
        <br />
        6.8. Users understand and accept that the provision of the Service may be affected by certain objective
        conditions and that Users use the Service at their own risk. Accordingly, the Administrator cannot be held
        liable for any information and data in the Application Content, including but not limited to text, images, video
        sequences, avatars or any other activity related to the use, as well as any other legal effect arising
        therefrom.
        <br />
        <br />
        6.9. Users understand and agree that the Administrator is absolved from any liability in the event of any
        shutdown, interruption, difficulty, malfunction or error in the operation of the Application, in the event of
        any technical failure of any kind or any failure in the provision of the Service, and in any situation in which
        the Users would not certainly prove that any of the aforementioned errors or problems of a technical nature are
        due directly and exclusively to the gross negligence of the Administrator.
        <br />
        <br />
        6.10. Users expressly understand and agree that the Administrator is absolved from any liability for any direct
        or indirect damages of any kind arising from the use of the Service or any other matter in connection with the
        Service, as well as from the use of the Application Content in any manner or any legal effects arising
        therefrom.
        <br />
        <br />
        6.11. In cases of force majeure, the Administrator and/or its operators, collaborators, employees and
        representatives shall be fully exonerated from liability. Cases of force majeure include, but are not limited
        to, malfunctions of the Administrator's technical equipment, failure of the Internet connection, failure of
        telephone connections,
        <h3 className={'font-big font-weight-light-bold paragrah'}>7. INFORMATION NOTE ON PERSONAL DATA PROTECTION</h3>
        7.1. In accordance with the requirements of the applicable legislation on the protection of individuals with
        regard to the processing of personal data and the free movement of such data, as well as Law No 506/2004 on the
        processing of personal data and the protection of privacy in the electronic communications sector, the
        Administrator shall manage in a secure manner and only for the specified purposes, the personal data provided by
        the Users, as well as the personal data entered into the database of the Application. We inform you that the
        personal data that the Users provide to the Administrator for logging in are processed exclusively for the
        purpose of providing the Service in optimal conditions, and the personal data entered by Users in the
        Application's database after logging in are processed exclusively for medical purposes, archiving in the public
        interest, scientific research or statistical purposes.
        <br />
        <br />
        7.2. For detailed information on the processing of personal data, please read the Data Protection Policy, which
        is an integral part of the Terms and Conditions.
        <h3 className={'font-big font-weight-light-bold paragrah'}>8. DURATION AND TERMINATION OF THE CONTRACT</h3>
        8.1. The Contract shall be deemed to be concluded for the entire period during which the User uses the
        Application, the Terms and Conditions being applicable for the same period.
        <br />
        <br />
        8.2. The User may terminate the use of the Application at any time, provided that the data entered until that
        time in the Application will not be automatically deleted, as it is necessary for the purposes set out in this
        document, as described in detail in the Data Protection Policy.
        <br />
        <br />
        8.3. The Administrator has the right to immediately suspend or terminate the Services offered to a User at any
        time and without notice: <br />
        a) if it becomes aware of, or has reasonable clues that the User is in breach of the Terms and Conditions, the
        Data Protection Policy or other policies published on the Application; <br />
        b) in the event where the account is not used for a long period of time.
        <h3 className={'font-big font-weight-light-bold paragrah'}>9. APPLICABLE LAW. DISPUTES</h3>
        9.1. The rights and obligations of the Users and the Administrator set forth in the Terms and Conditions, as
        well as all legal effects that the Terms and Conditions may generate, shall be interpreted and governed in
        accordance with the Romanian law in force.
        <br />
        <br />
        9.2. Any dispute arising out of or in connection with the Terms and Conditions shall be settled amicably. If an
        agreement cannot be reached, the dispute will be settled by the competent Romanian court of law, located in the
        district of Docviser's headquarters.
        <h3 className={'font-big font-weight-light-bold paragrah'}>10. MODIFICATION OF THE TERMS AND CONDITIONS</h3>
        10.1. The Administrator has the right to modify any of the provisions of the Terms and Conditions at any time
        and in any way, without prior notice and without being obliged to fulfil any other formality towards the Users.
        <br />
        <br />
        10.2. Any modification shall be deemed to have been fully and unconditionally accepted by any User by simply
        using or accessing any of the facilities offered by the Application or the Service or by accessing the
        Application at any time after the modification has been made. Failure to accept any modification shall oblige
        the User to immediately cease accessing the Application and/or using the Service in any way.
        <br />
        <br />
        10.3. The User is advised to periodically check the Terms and Conditions.
        <br />
        <br />
      </div>
    </>
  );
};

export default TermsEn;
