import React from "react";
import AlexLazar from '../../assets/images/webinar/lazar.png';
import AlexIliescu from '../../assets/images/webinar/iliescu.png';

const Speakers = () => {
  return (
    <div className="home__main-color-container home">
      <div className="main main__inner">
        <div className="speakers">
          <div className="speakers-header">
            <div className="home-section-tag">SPEAKERI</div>
            <h2 className="home-title margin-top-8">
              Cine sunt speaker-ii?
            </h2>
            <div className="home-subtitle__speakers speakers-description">
              Suntem dedicați în a îmbunătăți eficiența în centrele oncologice pentru ca tu să te poți concentra pe ceea ce contează - tratarea pacienților.
            </div>
          </div>
          <div className={'right-side'}>
            <div className={'card'}>
              <img src={AlexLazar} alt={'alex_lazar'}/>
              <div className={'card-title'}>
                Alexandru Lazăr
              </div>
              <div className={'card-subtitle'}>
                Co-Founder & Chief Executive Officer
              </div>
              <div className={'card-description'}>
                Lider în tehnologie cu peste 15 ani experiență în furnizarea de soluții software pentru companii cunoscute în toată lumea. A dezvoltat o pasiune pentru medicină și crede că viitorul medicinei e strâns legat de o corelare perfectă a acesteia cu tehnologia unde momentan e un gol enorm neexploatat suficient.
              </div>
            </div>
            
            <div className={'card card-second'}>
              <img src={AlexIliescu} alt={'alex_iliescu'} />
              <div className={'card-title'}>
                Alexandru Iliescu
              </div>
              <div className={'card-subtitle'}>
                Co-Founder & Chief Scientist
              </div>
              <div className={'card-description'}>
                Directorul Medical al Spitalului Județean de Urgență Zalău, specializat pe Oncologie, cu o pasiune pentru healthcare și tehnologie. Cu foarte multă inițiativă a reabilitat secția de Oncologie si totodată a contribuit activ la dezvoltarea Docviser ca platformă digitală specializată pe oncologie, rezolvând astfel foarte multe probleme medicale, de logistică și administrative ale clinicii în care activează.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Speakers;
