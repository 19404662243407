import 'lazysizes';
import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { withTranslation } from 'gatsby-plugin-react-i18next';

import HomeThumbnail from '../assets/images/homePage/hero.png';
import AboutUs1 from '../assets/images/aboutUs/aboutUs1.png';
import AboutUs2 from '../assets/images/aboutUs/aboutUs2.png';
import AboutUs3 from '../assets/images/aboutUs/aboutUs3.png';
import aboutUsData from '../data/about-us-data';
import Layout from '../components/GatsbyLayout';
import Helmet from '../components/Helmet';

const thumbnailPage = HomeThumbnail;

const renderSectionSubtitle = (title1, span, afterTitle, title2) => (
  <div className="title-container">
    <h2 className="home-title">
      {title1}
      <span className={'home-title text-gradient'}>{span}</span>
      {title2}
      <span className={'home-title text-gradient'}>.</span>
    </h2>
    <div className="description">{afterTitle}</div>
  </div>
);

const renderHelmet = t => (
  <Helmet
    title={t('aboutUs.seoTitle')}
    description={t('aboutUs.seoDescription')}
    keywords={t('aboutUs.seoKeywords')}
    thumbnail={thumbnailPage}
    canonical
    canonicalPage="about-us"
  />
);

const renderSection = ({ subtitle, content, contentClassNames }) => (
  <div className="title-container title-container-big-gap title-container-gap-10">
    {subtitle}
    <div className={classnames(contentClassNames)}>{content}</div>
  </div>
);

renderSection.propTypes = {
  subtitle: PropTypes.string,
  content: PropTypes.shape({}),
  contentClassNames: PropTypes.string,
};

renderSection.defaultProps = {
  subtitle: null,
  content: null,
  contentClassNames: null,
};

const renderWeAreDocviserSection = () => {
  return [
    <img
      key={'first_img'}
      className="content__person-image lazyload"
      data-src={AboutUs1}
      alt={'aboutUs1'}
      title={'aboutUs1'}
    />,
    <img
      key={'second_img'}
      className="content__person-image margin-bottom lazyload"
      data-src={AboutUs2}
      alt={'aboutUs2'}
      title={'aboutUs2'}
    />,
    <img
      key={'last_img'}
      className="content__person-image lazyload"
      data-src={AboutUs3}
      alt={'aboutUs3'}
      title={'aboutUs3'}
    />,
  ];
};
const renderGetToKnowUsSection = t => {
  const baseClsNames = '4u(xlarge) 6u(large) 6u(medium) 6u(small) 12u(xsmall) 12u(xxsmall) person';
  const getToKnowUsContent = [];

  /* eslint-disable-next-line */
  aboutUsData.map(data => {
    const classNames = classnames(baseClsNames, { hidden: data.hidden });

    !data.hidden &&
      getToKnowUsContent.push(
        <div key={`person_img_${data.name}`} className={classNames} tabIndex="0">
          <img
            className="content__person-image lazyload"
            data-src={data.image.default}
            alt={data.name}
            title={data.name}
          />
          <div className="content__person-details">
            <h3 className="content__person-name font-subtitle">{data.name}</h3>
            <h4 className="content__person-position font-label font-weight-normal">{data.position}</h4>
          </div>
        </div>
      );
  });

  return renderSection({
    subtitle: renderSectionSubtitle(
      t('aboutUs.subsectionTitle1'),
      t('aboutUs.subsectionSpan'),
      t('aboutUs.sectionDescription'),
      t('aboutUs.subsectionTitle2')
    ),
    content: getToKnowUsContent,
    contentClassNames: 'content about-us-content',
  });
};

const aboutUs = ({ location, t }) => {
  return (
    <Layout location={location}>
      <div className="home">
        {renderHelmet(t)}
        <h1 className="show-for-seo">{t('aboutUs.name')}</h1>
        <div className="main main__inner about-us">
          {/*{renderSection({*/}
          {/*  subtitle: renderSectionSubtitle(*/}
          {/*    t('aboutUs.sectionTitle'),*/}
          {/*    t('aboutUs.sectionTitle2'),*/}
          {/*    t('aboutUs.sectionDescription')*/}
          {/*  ),*/}
          {/*  content: renderWeAreDocviserSection(),*/}
          {/*  contentClassNames: 'content content__small about-us-content',*/}
          {/*})}*/}
          {renderGetToKnowUsSection(t)}
        </div>
      </div>
    </Layout>
  );
};

export default withTranslation()(aboutUs);

aboutUs.propTypes = {
  location: PropTypes.shape({}).isRequired,
  data: PropTypes.shape({}),
};

aboutUs.defaultProps = {
  data: null,
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { ns: { in: ["index"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
