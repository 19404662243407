import React from 'react';
import PropTypes from 'prop-types';

const HamburgerMenu = (props) => {
  const {
    width: widthValue,
    height: heightValue,
    isOpen,
    strokeWidth,
    animationDuration,
    rotate,
    color,
    borderRadius,
    menuClicked,
  } = props;

  const width = `${widthValue}px`;
  const height = `${heightValue}px`;
  const halfHeight = `${parseInt(height.replace('px', ''), 10) / 2}px`;
  const marginTop = `-${strokeWidth / 2}px`;

  const getTransformValue = (currentIsOpen, defaultPos, rotateVal) => (
    `translate3d(0,${currentIsOpen ? halfHeight : defaultPos},0) rotate(${currentIsOpen ? `${rotateVal}deg` : '0'})`
  );

  const styles = {
    container: {
      width,
      height,
      position: 'relative',
      transform: `rotate(${rotate}deg)`,
    },
    lineBase: {
      display: 'block',
      height: `${strokeWidth}px`,
      width: '100%',
      background: color || '#000',
      transitionTimingFunction: 'ease',
      transitionDuration: `${animationDuration}s`,
      borderRadius: `${borderRadius}px`,
      transformOrigin: 'center',
      position: 'absolute',
    },
    firstLine: {
      transform: getTransformValue(isOpen, 0, 45),
      marginTop,
    },
    secondLine: {
      transitionTimingFunction: 'ease-out',
      transitionDuration: `${animationDuration / 4}s`,
      opacity: isOpen ? '0' : '1',
      top: halfHeight,
      marginTop,
    },
    thirdLine: {
      transform: getTransformValue(isOpen, height, -45),
      marginTop,
    },
  };

  return (
    <div style={styles.container} onClick={menuClicked}>
      <span style={{ ...styles.lineBase, ...styles.firstLine }} />
      <span style={{ ...styles.lineBase, ...styles.secondLine }} />
      <span style={{ ...styles.lineBase, ...styles.thirdLine }} />
    </div>
  );
};

HamburgerMenu.propTypes = {
  isOpen: PropTypes.bool,
  menuClicked: PropTypes.func,
  width: PropTypes.number,
  height: PropTypes.number,
  strokeWidth: PropTypes.number,
  rotate: PropTypes.number,
  color: PropTypes.string,
  borderRadius: PropTypes.number,
  animationDuration: PropTypes.number,
};

HamburgerMenu.defaultProps = {
  isOpen: false,
  menuClicked: () => {},
  width: 36,
  height: 30,
  strokeWidth: 2,
  rotate: 0,
  color: '#000',
  borderRadius: 0,
  animationDuration: 0.4,
};

export default HamburgerMenu;
