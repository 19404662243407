module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-breakpoints/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"2671070069785770"},
    },{
      plugin: require('../node_modules/gatsby-plugin-cookiehub/gatsby-browser.js'),
      options: {"plugins":[],"cookihubId":"2e5f3540","trackingId":"\"G-K74PVGBZQ3\"","gtagId":true,"head":false,"anonymize":true},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":800,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"DOCVISER","short_name":"DOCVISER","description":"DOCVISER | Oncology","icon":"src/assets/images/logo.png","icon_options":{"purpose":"any maskable"},"start_url":"/","background_color":"#FFFFFF","theme_color":"#212121","display":"standalone","cache_busting_mode":"none","legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"G-K74PVGBZQ3","head":false,"anonymize":false,"respectDNT":false,"exclude":["/preview/**","/do-not-track/"],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"G-H1MR0CQ4H5","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"docviser-site"}},"routeChangeEventName":"docviser-site-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/about-us/*","ro/about-us/*","/blog/*","ro/blog/*"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"languages":["en","ro"],"defaultLanguage":"en","siteUrl":"https://docviser.io/","i18nextOptions":{"fallbackLng":"en","supportedLngs":["en","ro"],"defaultNS":"common","interpolation":{"escapeValue":false}},"pages":[{"matchPath":"/404.html","languages":["en"]}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
