import React from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import { withTranslation } from 'gatsby-plugin-react-i18next';
import '../assets/css/index.css';

import { ChooseDocviserSection } from '../components/Sections/ChooseDocviserSection';
import { CertificationsSection } from '../components/Sections/CertificationsSection';
import { TestimonialsSection } from '../components/Sections/TestimonialsSection';
import HomeMainSectionIcon from '../assets/images/homePage/hero.png';
import { AboutSection } from '../components/Sections/AboutSection';
import Background from '../assets/images/homePage/background.png';
import Subtitle from '../components/Subsection/Subtitle';
import Next from '../assets/images/homePage/next.png';
import Layout from '../components/GatsbyLayout';
import { features } from '../data/Features';
import Article from '../components/Article';
import Helmet from '../components/Helmet';
import Button from '../components/Button';

class HomeIndex extends React.Component {
  static getFeaturedArticles(edges, lng) {
    return edges
      .sort((edge1, edge2) => {
        return new Date(edge1.node.frontmatter.date.date) - new Date(edge2.node.frontmatter.date.date);
      })
      .filter(edge => {
        console.log('edge.node.frontmatter.lang ------------------->> ', edge.node.frontmatter.lang);
        return edge.node.frontmatter.lang === lng;
      })
      .slice(0, 3)
      .map(edge => (
        <Article
          key={edge.node.id}
          title={edge.node.frontmatter.title}
          category={edge.node.frontmatter.category}
          readingTime={edge.node.timeToRead}
          date={edge.node.frontmatter.date}
          path={edge.node.frontmatter.path}
          imageFluid={edge.node.frontmatter.mainImage.childImageSharp.fluid}
          imageFluidMobile={
            edge.node.frontmatter.mobileImage && edge.node.frontmatter.mobileImage.childImageSharp.fluid
          }
        />
      ));
  }

  constructor(props) {
    super(props);

    const {
      data: {
        allMarkdownRemark: { edges },
      },
      t,
    } = props;

    const posts = HomeIndex.getFeaturedArticles(edges, t('lang'));

    this.state = {
      posts,
    };

    this.renderHelmet = this.renderHelmet.bind(this);
    this.renderBlog = this.renderBlog.bind(this);
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      if (window.location.search === '?calendly=true') {
        push('/contact/');
        setTimeout(() => {
          window.location = 'https://calendly.com/alex-docviser-1/30min';
        }, 1000);
      }
    }
  }

  renderHelmet = () => {
    const { t } = this.props;
    return (
      <Helmet
        title={t('home.seoTitle')}
        description={t('home.seoDescription')}
        keywords={t('home.seoKeywords')}
        canonical
      />
    );
  };

  renderSubtitle = ({ title, description, button, classNames }) => (
    <Subtitle
      title={title}
      afterTitle={description}
      titleType="withDot"
      withoutLine
      classNames={classNames}
      specialLayout={children => (
        <div className="home__subsection--button-layout">
          {children}
          {button}
        </div>
      )}
    />
  );

  renderSectionButton = (title, linkTo, className) => (
    <Button
      internalLink
      type="primary-large-light"
      goTo={linkTo}
      title={title}
      classNames={`home__subsection__button ${className || ''}`}
    />
  );

  renderBlogButton = className => this.renderSectionButton('View All Blog Posts', '/blog/', className);

  renderBlog = () => {
    const { posts } = this.state;

    return (
      <div className="home__subsection home__section section__blog">
        <div className="home__subsection__title-blog">
          {this.renderSubtitle({
            title: 'Featured articles',
            description: 'Head to our blog for industry insights and best practice advice.',
            button: this.renderBlogButton(),
          })}
        </div>
        <div className="post__latest-items articles">{posts}</div>
        {this.renderBlogButton('mobile')}
      </div>
    );
  };

  renderMainSection = () => {
    const { t } = this.props;

    return (
      <div className="home__main-section home__header-container">
        <div className="home__header">
          <div className="main home-main">
            <div className="title-container">
              <div>
                <div className="main-title">
                  {t('home.mainSection.titleOptimize')}
                  <span className={'main-title text-gradient'}>{t('home.mainSection.oncology')}</span>
                </div>
              </div>
              <div className={'description'}>{t('home.mainSection.descriptionInnovation')}</div>
            </div>
            <Button goTo="/contact/" type="primary-dark-button" title={t('home.mainSection.joinFree')} />
            <div>
              <img src={HomeMainSectionIcon} alt="Home" className="home-main__text-image" />
              <img src={Background} alt="Background" className="home-main__bg-image" />
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderMainFeaturesSection = () => {
    const { t } = this.props;

    return (
      <div className="title-container title-container-big-gap">
        <div className="title-container">
          <h2 className="home-title">
            {t('home.mainFeaturesSection.title')}
            <span className="text-gradient">.</span>
          </h2>
          <div className="description">{t('home.mainFeaturesSection.description')}</div>
        </div>
        <div className={'features-container'}>
          {features.map((feature, index) => (
            <Link to={feature.link} className={`card ${index === 1 ? 'card-large' : ''}`} key={`feature-${index}`}>
              <img src={feature.icon} alt={feature.title} className={'img-container'} />
              <div className={'text-container'}>
                <div>{t(`home.mainFeaturesSection.titles.${feature.title}`)}</div>
                <div className={'description description-no-width'}>
                  {t(`home.mainFeaturesSection.content.${feature.title}`)}
                </div>
              </div>
            </Link>
          ))}
          <Link target="_self" to={'/product/'} className={'card card-img'}>
            <img src={Next} alt="next" />
          </Link>
        </div>
      </div>
    );
  };

  renderBlogSection = () => {
    const { posts } = this.state;
    const { t } = this.props;

    return (
      <div className="title-container title-container-big-gap">
        <div className="title-container">
          <h2 className="home-title">
            {t('home.latestBlogSection.title')}
            <span className="text-gradient">.</span>
          </h2>
          <div className="description">{t('home.latestBlogSection.section')}</div>
        </div>
        <div className="post__latest-items articles">{posts}</div>
      </div>
    );
  };

  render() {
    const { location, t } = this.props;

    return (
      <Layout location={location}>
        <div className="home">
          {this.renderHelmet(t)}
          <h1 className="show-for-seo">{t('home.h1')}</h1>
          <div className="main main__inner">
            {this.renderMainSection()}

            <AboutSection t={t} />

            {this.renderMainFeaturesSection()}

            <TestimonialsSection t={t} />

            <ChooseDocviserSection t={t} />
            <CertificationsSection t={t} />

            {this.renderBlogSection()}
          </div>
        </div>
      </Layout>
    );
  }
}

export default withTranslation()(HomeIndex);

HomeIndex.propTypes = {
  data: PropTypes.shape({}).isRequired,
  location: PropTypes.shape({}).isRequired,
};

export const pageQuery = graphql`
  query PageQuery($language: String!) {
    locales: allLocale(filter: { ns: { in: ["index", "about-us"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(sort: { frontmatter: { date: DESC } }) {
      edges {
        node {
          id
          timeToRead
          frontmatter {
            title
            lang
            date(formatString: "MMM DD, YYYY")
            tags
            path
            category
            mainImage {
              absolutePath
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
