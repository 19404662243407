import React, { useEffect } from 'react';

import ISO_13485 from '../assets/pdfs/Iso_13485.pdf';

const ISO13485 = () => {
  useEffect(() => {
    document.getElementById('ISO_13485').click();
  }, []);

  return <a id="ISO_13485" href={ISO_13485} target="_self" />;
};

export default ISO13485;
