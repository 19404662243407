import React from 'react';
import PropTypes from 'prop-types';

import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from 'react-share';

import FacebookIcon from '../assets/images/blog/facebook.svg';
import TwitterIcon from '../assets/images/blog/twitter.svg';
import LinkedinIcon from '../assets/images/blog/linkedin.svg';

/**
 * Supported icons: Facebook, Twitter, Linkedin
 * @param quote
 * @param socialType
 * @param url
 * @returns {*}
 * @constructor
 */
const SocialShare = ({ quote, socialType, url }) => {
  let Icon;
  let Button;

  switch (socialType) {
    case 'Facebook':
      Icon = FacebookIcon;
      Button = FacebookShareButton; break;
    case 'Twitter':
      Icon = TwitterIcon;
      Button = TwitterShareButton; break;
    default:
      Icon = LinkedinIcon;
      Button = LinkedinShareButton; break;
  }

  const socialNotFacebook = socialType !== 'Facebook';

  return (
    <Button
      className="react-share-wrapper"
      url={url}
      {...socialNotFacebook ? { title: quote } : { quote }}
    >
      <div className="label">{quote}</div>
      <img src={Icon} alt='social_share'/>
    </Button>
  );
};

SocialShare.propTypes = {
  quote: PropTypes.string,
  socialType: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

SocialShare.defaultProps = {
  quote: '',
};

export default SocialShare;
