import React from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import { withTranslation } from 'gatsby-plugin-react-i18next';

import '../assets/css/index.css';
import Helmet from '../components/Helmet';
import Layout from '../components/GatsbyLayout';
import { featuresProduct } from '../data/Features';
import HomeThumbnail from '../assets/images/homePage/hero.png';
import { AboutSection } from '../components/Sections/AboutSection';
import { TestimonialsSection } from '../components/Sections/TestimonialsSection';
import { ChooseDocviserSection } from '../components/Sections/ChooseDocviserSection';
import { CertificationsSection } from '../components/Sections/CertificationsSection';

const renderMainFeaturesSection = t => {
  return (
    <div className="title-container title-container-big-gap">
      <div className="title-container">
        <h2 className="home-title">
          {t('home.mainFeaturesSection.title2')}
          <span className={'home-title text-gradient'}>{t('home.mainFeaturesSection.title3')}.</span>
        </h2>
        <div className="description">{t('home.mainFeaturesSection.description')}</div>
      </div>
      <div className={'features-container'}>
        {featuresProduct.map((feature, index) => (
          <Link key={index} target="_self" to={feature.link} className={`card ${index === 4 ? 'card-large' : ''}`}>
            <img src={feature.icon} alt={feature.title} className={'img-container'} />
            <div className={'text-container'}>
              <div>{t(`home.mainFeaturesSection.titles.${feature.title}`)}</div>
              <div className={'description description-no-width'}>
                {t(`home.mainFeaturesSection.content.${feature.title}`)}
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

const renderHelmet = t => (
  <Helmet
    title={t('product.seoTitle')}
    description={t('product.seoDescription')}
    keywords={t('product.seoKeywords')}
    thumbnail={HomeThumbnail}
    canonical
    canonicalPage="product"
  />
);
const product = ({ location, t }) => {
  return (
    <Layout location={location}>
      <div className="home">
        {renderHelmet(t)}
        <h1 className="show-for-seo">{t('home.h1')}</h1>
        <div className="main main__inner">
          {renderMainFeaturesSection(t)}
          <AboutSection t={t} />
          <TestimonialsSection t={t} />
          <CertificationsSection t={t} />
          <ChooseDocviserSection t={t} />
        </div>
      </div>
    </Layout>
  );
};

export default withTranslation()(product);

product.propTypes = {
  location: PropTypes.shape({}).isRequired,
  data: PropTypes.shape({}),
};

product.defaultProps = {
  data: null,
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { ns: { in: ["index"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
