import React from 'react';
import Slider from 'react-slick/lib';
import ArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import ArrowRight from '@mui/icons-material/KeyboardArrowRight';

import Quote from '../../assets/images/homePage/quote.png';
import { testimonials } from '../../data/Testimonials';
import Testimonial from '../Testimonial.jsx';

export const TestimonialsSection = ({ t }) => {
  const settings = {
    infinite: true,
    centerPadding: '50px',
    slidesToShow: 1,
    speed: 500,
    adaptiveHeight: true,
    dots: false,
    lazyLoad: 'progressive',

    responsive: [
      {
        breakpoint: 480,
        settings: {
          touchMove: true,
          dots: false,
          arrows: true,
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          swipeToSlide: true,
          centerPadding: '74px',
          verticalSwiping: false,
          horizontalSwiping: true,
          touchThreshold: 10,
        },
      },
    ],
  };

  const clickPrev = () => {
    document.querySelector('.slick-prev').click();
  };
  const clickNext = () => {
    document.querySelector('.slick-next').click();
  };

  return (
    <div className="title-container title-container-big-gap">
      <div className="title-container">
        <h2 className="home-title">
          {t('home.testimonialsSection.title')}
          <span className="text-gradient">.</span>
        </h2>
        <div className="description">{t('home.testimonialsSection.description')}</div>
      </div>
      <div className={'features-container'}>
        <div className="card card-small grow">
          <div className="home-main home-main-small-padding">
            <div className={'number-text'}>200+</div>
            <div className={'testimonials-text'}>
              {t('home.testimonialsSection.usersText')} <span>Docviser</span>
              {t('home.testimonialsSection.usersText2')}
            </div>
          </div>
        </div>
        <div className="slider-layout">
          <div className="header">
            <img src={Quote} alt="chat" />
            <div className="header header-buttons">
              <ArrowLeft onClick={clickPrev} className="slider-arrow" />
              <ArrowRight onClick={clickNext} className="slider-arrow" />
            </div>
          </div>
          <Slider {...settings}>
            {testimonials.map(testimonial => (
              <Testimonial
                key={testimonial.quote}
                quote={`"${t(testimonial.quote)}"`}
                imgSrc={testimonial.imageSrc}
                position={t(testimonial.position)}
                author={testimonial.author}
              />
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};
