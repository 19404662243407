import React from 'react';
import PropTypes from 'prop-types';
import Layout from '../components/GatsbyLayout';
import Helmet from '../components/Helmet';
import { graphql } from 'gatsby';
import { withTranslation } from 'gatsby-plugin-react-i18next';
import PrivacyEn from '../components/Privacy/PrivacyEn';
import PrivacyRo from '../components/Privacy/PrivacyRo';

const PrivacyPolicy = ({ location, t }) => {
  const Template = () => (t('content.template') === 'PrivacyEn' ? <PrivacyEn /> : <PrivacyRo />);
  return (
    <>
      <Helmet title={t('seoTitle')} description={t('seoDescription')} keywords={t('seoKeywords')} canonical />
      <Layout location={location}>
        <div className="main post">
          <div className="main__inner">
            <div className="main__section">
              <div id="article-post-content" className="post__content">
                <Template />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

PrivacyPolicy.propTypes = {
  location: PropTypes.shape({}).isRequired,
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { ns: { in: ["privacy-policy"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default withTranslation()(PrivacyPolicy);
